        import { useState, useEffect, useRef } from "react";
        import SideNav from "./SideNav";
        import TopBar from "./TopBar";
        import axios from 'axios';
        import Swal from 'sweetalert2';
        import {Link, useParams, useNavigate} from "react-router-dom";
        import placeholderImage from '../img/image_placeholder.png';
        import noImagePlaceholder from '../img/no_image_placeholder.png';

        function EditTeamMember(){
            const { id } = useParams();
            const [isLoading, setIsLoading] = useState(true);
            const [isImageLoading, setIsImageLoading] = useState(true);
            const [loading, setLoading] = useState(false);
            const API_URL = process.env.REACT_APP_API_URL;
            const LARAVEL_API_URL = process.env.REACT_APP_LARAVEL_API;
            const [team, setTeam] = useState([]);
            const [login, setLogin] = useState([]);
            const [error_list, setErrorList] = useState([]);
            const navigate = useNavigate();
            const [user_photo, setUser_photo] = useState("");
            const [showPassword, setShowPassword] = useState(false);
            const [imagePreview, setImagePreview] = useState(null); 
            const fileInputRef = useRef(null);

            const handleInput = (e) => {
                setTeam({ ...team, [e.target.name]: e.target.value });
                }

                const handleLoginInput = (e) => {
                    setLogin({ ...login, [e.target.name]: e.target.value });
                }
        

            const handleFileChange = (event) => {
                const file = event.target.files[0];
                if (file) {
                    setUser_photo(file);
                    setTeam({ ...team, user_photo: file }); // Store the file in the component state
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        setImagePreview(reader.result); // Store the file's data URL
                    };
                    reader.readAsDataURL(file);
                }else {
                    setImagePreview(null); // Reset the image preview if no file is selected
                }
            
            
            };

            
            const handleImageLoad = () => {
                setIsImageLoading(false);
            };

            const togglePasswordVisibility = () => {
                setShowPassword(!showPassword);
            };

            useEffect(() => {
                setIsLoading(true);
                axios.get(`${API_URL}/edit-team-member/`+id, ).then(res=>{
                if(res.data.status === 200){
                    setTeam(res.data.team); 
                    setLogin(res.data.login);
                }
                else if(res.data.status === 404){
                Swal("Error",res.data.error,"error");
                    //navigate.push('/view-inventory');
                    //console.log(res.data.error)
                }
                setIsLoading(false);
                });
            }, []);

            async function update(event)
            {
                event.preventDefault();
                setLoading(true); 
                const formData = new FormData()
                // const data = customer;
            formData.append('user_photo', team.user_photo);
            formData.append('user_role', team.user_role);
            formData.append('first_name', team.first_name);
            formData.append('last_name', team.last_name);
            formData.append('phone_no', team.phone_no);
            formData.append('email', team.email);
            formData.append('address', team.address);
            formData.append('username', login.username);
            formData.append('password', login.password);


                    await axios.post(`${API_URL}/update-team-member/${id}`, formData).then(res =>{
            Swal.fire({
                    icon:"success",
                    text:res.data.message
                    }).then(function(){
                        setErrorList([]);
                        navigate('/view-team');
                    
                });
                }).catch(function(error) {
                    // if(error.response.status===422){         
                    setErrorList(error.response.data.validate_err);
                    // }
                //    else{
                    Swal.fire({
                    
                        text:error.response.data.message,
                        icon:"error"
                    })
                    setLoading(false); 
                //    }
                })


                
                }

            return(
        <div>
        <SideNav/>
        <TopBar/>
        {isLoading?
            <div className="loader-container" style ={{backgroundColor: "rgba(0, 0, 0, 0.5)", position: "fixed",top: "0",left: "0",width: "100%",height: "100%",display: "flex",justifyContent: "center",alignItems: "center",zIndex: "9999"}}>
            <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
        </div>
        </div>
        </div>:null}

                
        <div class="right_panel position create_inventory h-100" id="right_panel">
        
        <div class="main_title" data-aos="fade-down" data-aos-delay="" data-aos-duration="1200">
            <h1><i class="bi bi-plus-lg h1"></i> Edit Team Member</h1>
        </div>

        <div class="content_wrapper pt-0">
            
            <div id="inventory_section1">
                <div class="col" data-aos="fade-down" data-aos-delay="" data-aos-duration="1200">
                    <div class="vm_content_box_1">
                        

                    <div class="content pt-3 pb-4 mb-5">
                            
                            <div className="row">
                                <div class="col-md-3">
                                    <div class=" form-group  text-center">
                                        <label for="firstname">Update Photo</label>
                                        {team.user_photo_path ?
                                        <img style={{ width: '150px', height: '120px', display: 'block', margin: '2px auto' }} src={isImageLoading ? placeholderImage : ( imagePreview  || `${LARAVEL_API_URL}/${team.user_photo_path}` )} onLoad={handleImageLoad} />
                                    : <img style={{ width: '150px', height: '120px', display: 'block', margin: '2px auto' }} src={ imagePreview || noImagePlaceholder} />}
                                    
                                    
                                        <input type="file" class="d-none"  ref={fileInputRef}   name="user_photo" id="user_photo"  onChange={handleFileChange} />
                                        <button class="btn btn-sm btn_secondary text-white" onClick={() => fileInputRef.current.click()} style={{ width: '150px', marginTop: '10px' }}  >
                <i class="bi bi-cloud-upload me-2"></i> Upload Photo
            </button>
                                    </div>
                                    </div>
                                    <div class="col-md-4">
                                    <div class=" form-group">
                                        <label for="firstname">First Name</label>
                                        <input type="text" class="form-control" name="first_name" id="first_name" placeholder="Enter First Name" value={team.first_name} 
                                    onChange={handleInput }/>
                                        <span class="text-danger">{error_list.first_name}</span>
                                    </div>

                                    <div class="form-group">
                                        <label for="email">Email Address</label>
                                        <input type="text" class="form-control" name="email" id="email" value={team.email} placeholder="Enter Email Address"
                                        onChange={handleInput }/>
                                            <span class="text-danger">{error_list.email}</span>
                                    </div>
                                    <div class="form-group">
                                        <label for="phone">Phone Number</label>
                                        <input type="text" class="form-control" name="phone_no" id="phone_no" placeholder="Enter Phone Number" value={team.phone_no}
                                    onChange={handleInput }/>
                                        <span class="text-danger">{error_list.phone_no}</span>
                                    </div>
                                    <div class="form-group">
                                        <label for="username">Username</label>
                                        <input type="text" class="form-control" name="username" id="username" placeholder="Enter Username" value={login.username}
                                        onChange={ handleLoginInput } />
                                        <span class="text-danger">{error_list.username}</span>
                                    </div>
                                
                                    </div>
                                    <div class="col-md-4">
                                    <div class=" form-group">
                                        <label for="lastname">Last Name</label>
                                        <input type="text" class="form-control" name="last_name" id="last_name" placeholder="Enter Last Name" value={team.last_name}
                                        onChange={handleInput }/>
                                            <span class="text-danger">{error_list.last_name}</span>
                                    </div>
                                    <div class=" form-group">
                                        <label for="empcountry">Select User Role</label>
                                        <select class="form-select" name="user_role" id="user_role" value={team.user_role} onChange={handleInput}>
                                        <option value="">Select User Type</option>
                                            <option value="Super Admin">Super Admin</option>
                                            <option value="Admin">Admin</option>
                                            <option value="Manager">Manager</option>
                                            <option value="Sales">Sales</option>
                                        </select>
                                        <span class="text-danger">{error_list.user_role}</span>
                                    </div>

                                    <div class=" form-group">
                                        <label for="address">Address</label>
                                        <input type="text" class="form-control" name="address" id="address" value={team.address} placeholder="Address"
                                        onChange={handleInput }/>
                                            <span class="text-danger">{error_list.address}</span>
                                    </div>
                                    <div class="form-group" style={{ position: 'relative' }}>
                                        <label for="password">Password</label>
                                        <input  type={showPassword ? 'text' : 'password'} class="form-control" name="password" id="password" placeholder="Enter Password" value={login.password}
                                        onChange={handleLoginInput }/>
                                            <button
                                            type="button"
                                            class={`btn btn-outline-secondary`}
                                            onClick={togglePasswordVisibility}
                                            style={{
                                                position: 'absolute',
                                                right: '10px',
                                                top: error_list.password ? '50%' : '60%', 
                                                transform: 'translateY(-50%)',
                                                background: 'none',
                                                border: 'none',
                                                cursor: 'pointer',
                                                color: showPassword ? '#212529' : '#6c757d' 
                                            }}
                                        >
                                            <i class={showPassword ? 'bi bi-eye-slash' : 'bi bi-eye'}></i>
                                        </button>
                                        <span class="text-danger">{error_list.password}</span>
                                    </div>

                                    </div>
                                    <div class="col-11 text-end mt-3" >

        <button onClick={update} class="btn btn-sm btn_secondary text-white"> {loading?"Updating":"Update"}</button>
        </div>   
                                
                                
                                </div>
                                </div>


                    </div>
                </div>

                
            </div>

        </div>
        </div>


        </div>



            );
        }
        export default  EditTeamMember;