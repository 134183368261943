import { useState, useEffect } from "react";
import SideNav from "./SideNav";
import TopBar from "./TopBar";
import axios from 'axios';
import Swal from 'sweetalert2';
import {Link, useParams, useNavigate} from "react-router-dom";
import placeholderImage from '../img/image_placeholder.png'
import { Placeholder } from "react-bootstrap";

function EditCustomer(){
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [isImageLoading, setIsImageLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const API_URL = process.env.REACT_APP_API_URL;
    const LARAVEL_API_URL = process.env.REACT_APP_LARAVEL_API;
    const [customer, setCustomer] = useState([]);
    const [error_list, setErrorList] = useState([]);
    const navigate = useNavigate();
    const [customer_photo, setCustomer_photo] = useState("");

    const handleInput = (e) => {
          setCustomer({ ...customer, [e.target.name]: e.target.value });
     
        }
   

      const handleFileChange = (event) => {
        setCustomer_photo(event.target.files[0]);
       setCustomer({ ...customer, customer_photo: event.target.files[0] });
      };

      
      const handleImageLoad = () => {
        setIsImageLoading(false);
    };



    useEffect(() => {
        setIsLoading(true);
        axios.get(`${API_URL}/edit-customer/`+id, ).then(res=>{
           if(res.data.status === 200){
              setCustomer(res.data.customer); 
           }
           else if(res.data.status === 404){
          Swal("Error",res.data.error,"error");
             //navigate.push('/view-inventory');
             //console.log(res.data.error)
           }
      
           setIsLoading(false);
        });
       }, []);

       async function update(event)
       {
        event.preventDefault();
        setLoading(true); 
        const formData = new FormData()
        // const data = customer;
   formData.append('customer_photo', customer.customer_photo || '');
formData.append('user_type', customer.user_type || '');
formData.append('first_name', customer.first_name || '');
formData.append('last_name', customer.last_name || '');
formData.append('phone_no', customer.phone_no || '');
formData.append('email', customer.email || '');
formData.append('purchased_on', customer.purchased_on || '');
formData.append('country', customer.country || '');
formData.append('address', customer.address || '');
formData.append('apart_or_suite', customer.apart_or_suite || '');
formData.append('city', customer.city || '');
formData.append('province', customer.province || '');
formData.append('postal_code', customer.postal_code || '');
formData.append('employer_name', customer.employer_name || '');
formData.append('employer_address', customer.employer_address || '');
formData.append('employer_country', customer.employer_country || '');
formData.append('employer_apart_or_suite', customer.employer_apart_or_suite || '');
formData.append('employer_city', customer.employer_city || '');
formData.append('employer_state', customer.employer_state || '');
formData.append('employer_postalcode', customer.employer_postalcode || '');
formData.append('job_title', customer.job_title || ''); // Empty string if not filled
formData.append('monthly_income', customer.monthly_income || ''); // Empty string if not filled
formData.append('vehicle_type', customer.vehicle_type || ''); // Empty string if not filled
formData.append('hear_about', customer.hear_about || '');

            await axios.post(`${API_URL}/update-customer/${id}`, formData).then(res =>{
     Swal.fire({
               icon:"success",
               text:res.data.message
             }).then(function(){
                 setErrorList([]);
                 navigate('/view-customers');
            
             
           });
           }).catch(function(error) {
              // if(error.response.status===422){         
              setErrorList(error.response.data.validate_err);
              // }
          //    else{
               Swal.fire({
              
                 text:error.response.data.message,
                 icon:"error"
               })
               setLoading(false); 
          //    }
           })


        
           }

    return(
<div>
<SideNav/>
<TopBar/>
{isLoading?
     <div className="loader-container" style ={{backgroundColor: "rgba(0, 0, 0, 0.5)", position: "fixed",top: "0",left: "0",width: "100%",height: "100%",display: "flex",justifyContent: "center",alignItems: "center",zIndex: "9999"}}>
    <div class="d-flex justify-content-center">
  <div class="spinner-border" role="status">
  </div>
  </div>
</div>:null}

        
<div class="right_panel position create_inventory h-100" id="right_panel">
  
  <div class="main_title" data-aos="fade-down" data-aos-delay="" data-aos-duration="1200">
      <h1><i class="bi bi-plus-lg h1"></i> Edit Customer</h1>
  </div>

  <div class="content_wrapper pt-0">
    
      <div id="inventory_section1">
          <div class="col" data-aos="fade-down" data-aos-delay="" data-aos-duration="1200">
              <div class="vm_content_box_1">
                  <div class="title">
                      <h2><i class="bi bi-person"></i> Personal Information</h2>
                  </div>
                  <div class="content pt-3">
                      <div class="row">
                          <div class="col-md-6 form-group">
                              <label for="firstname">Update Photo</label>
                              <input type="file" class="form-control vm_bg_secondary text-white" name="customer_photo" id="customer_photo"  onChange={handleFileChange}   />
                             <br></br>
                             {/* {customer.customer_photo_path?<img style={{width:'150px', height:'120px'}} src={`${LARAVEL_API_URL}/${customer.customer_photo_path}`}/>: <div style={{color:"blue"}}>No Image uploaded!!</div>}  */}
                           {customer.customer_photo_path?<img style={{width:'150px', height:'120px'}} src={isImageLoading?placeholderImage:`${LARAVEL_API_URL}/${customer.customer_photo_path}`} onLoad={handleImageLoad}/>: <div style={{color:"green"}}>No Image uploaded!</div>} 
                             {/* {(isImageLoading && customer.customer_photo_path) ? <img style={{width:"50%"}}  onLoad={handleImageLoad} src={placeholderImage} />
                                 : <img style={{width:"50%"}}   src={customer.customer_photo_path ? `${LARAVEL_API_URL}/${customer.customer_photo_path}` : placeholderImage} />
}
{customer.customer_photo_path && (
    <span 
      style={{
        position: "absolute",   
        top: "50%", 
        left: "50%", 
        color: "black",
        transform: "translate(-50%, -50%)", 
        color: "#000",
        backgroundColor: "rgba(255, 255, 255, 0.7)", // Optional: Adds a background to the text for better readability
        padding: "2px 5px", // Optional: Adds padding around the text
        borderRadius: "3px" // Optional: Rounds the corners of the text background
      }}
    >
      No image available
    </span>
)}    */}
                          </div>
                        
                          <div class="col-md-6 form-group">
                              <label for="empcountry">Select User Type</label>
                              <select class="form-select" name="user_type" id="user_type" value={customer.user_type} onChange={handleInput }>
                              <option value="">Select User Type</option>
                                  <option value="Leads">Leads</option>
                                  <option value="Customer">Customer</option>
                              </select>
                              <span class="text-danger">{error_list.user_type}</span>
                          </div>
                          <div class="col-md-6 form-group">
                              <label for="firstname">First Name</label>
                              <input type="text" class="form-control" name="first_name" id="first_name" placeholder="Enter Your First Name" value={customer.first_name} 
                              onChange={handleInput }/>
                               <span class="text-danger">{error_list.first_name}</span>
                          </div>
                          <div class="col-md-6 form-group">
                              <label for="lastname">Last Name</label>
                              <input type="text" class="form-control" name="last_name" id="last_name" placeholder="Enter Your Last Name" value={customer.last_name}
                              onChange={handleInput }/>
                                  <span class="text-danger">{error_list.last_name}</span>
                          </div>
                          <div class="col-md-6 form-group">
                              <label for="phone">Phone Number</label>
                              <input type="text" class="form-control" name="phone_no" id="phone" placeholder="Enter Your Phone Number" value={customer.phone_no}
                              onChange={handleInput }/>
                                 <span class="text-danger">{error_list.phone_no}</span>
                          </div>
                          <div class="col-md-6 form-group">
                              <label for="email">Email Address</label>
                              <input type="text" class="form-control" name="email" id="email" value={customer.email} placeholder="Enter Your Email Address"
                               onChange={handleInput }/>
                                  <span class="text-danger">{error_list.email}</span>
                          </div>
                          {/* <div class="col-md-6 form-group">
                              <label for="Purchasedon_date">Purchased on</label>
                              <div class="d-flex input_wrapper">
                                  <div class="icon_wrapper">
                                      <i class="bi bi-calendar2-plus icon"></i>
                                  </div>
                                  <input type="date" class="form-control" name="purchased_on" id="purchased_on" placeholder="Select Date" value={customer.purchased_on}  
                                  onChange={handleInput }/>
                              
                              </div>
                              <span class="text-danger">{error_list.purchased_on}</span>
                          </div> */}

<div class="col-md-12 form-group">
                              <label for="address">Address</label>
                              <input type="text" class="form-control" name="address" id="address" value={customer.address} placeholder="Address"
                              onChange={handleInput }/>
                                   <span class="text-danger">{error_list.address}</span>
                          </div>
                          <div class="col-md-6 form-group">
                              <label for="city">City</label>
                              <input type="text" class="form-control" name="city" id="city" value={customer.city}  placeholder="Enter Your City" 
                              onChange={handleInput }/>
                               <span class="text-danger">{error_list.city}</span>
                          </div>
                          <div class="col-md-6 form-group">
                          <label for="province">State</label>
                              <input type="text" class="form-control" name="province" value={customer.province} id="province" placeholder="State"
                               onChange={handleInput }/>
                              <span class="text-danger">{error_list.province}</span>
                          </div>
                          <div class="col-md-6 form-group">
                              <label for="postalcode">Postal Code</label>
                              <input type="text" class="form-control" name="postal_code" id="postal_code"  value={customer.postal_code} placeholder="Postal Code"
                               onChange={handleInput }/>
                              <span class="text-danger">{error_list.postal_code}</span>
                          </div>
                          <div class="col-md-6 form-group">
                              <label for="country">Country</label>
                              <select class="form-select" name="country" id="country"  value={customer.country} onChange={handleInput }>
                              <option value="">Select Country</option>
                                  <option value="Canada">Canada</option>
                                  <option value="United States">United States</option>
                              </select>
                              <span class="text-danger">{error_list.country}</span>
                          </div>
                        
                          {/* <div class="col-md-6 form-group">
                              <label for="apart_or_suite">Apt/Suite/Other</label>
                              <input type="text" class="form-control" name="apart_or_suite" id="apart_or_suite" value={customer.apart_or_suite}  placeholder="Apt/Suite/Other"
                              onChange={handleInput }/>
                                   <span class="text-danger">{error_list.apart_or_suite}</span>
                          </div> */}
                        
                      </div>
                  </div>
              </div>
          </div>

          <div class="col ms-lg-4 pt-4 pt-lg-0" data-aos="fade-down" data-aos-delay="" data-aos-duration="1200">
              <div class="vm_content_box_1 h-100">
                  <div class="title">
                      <h2><i class="bi bi-person"></i> Employer Information</h2>
                  </div>
                  <div class="content pt-3">
                      <div class="row">
                          <div class="col-md-6 form-group">
                              <label for="employer_name">Employer Name</label>
                              <input type="text" class="form-control" name="employer_name" id="employer_name" value={customer.employer_name}  placeholder="Employer Name"
                               onChange={handleInput }/>
                                <span class="text-danger">{error_list.employer_name}</span>
                          </div>
                          <div class="col-md-6 form-group">
                              <label for="employer_address">Employer Address</label>
                              <input type="text" class="form-control" name="employer_address" id="employer_address" value={customer.employer_address} placeholder="Employer Address"
                               onChange={handleInput }/>
                               <span class="text-danger">{error_list.employer_address}</span>
                          </div>
                          
                          <div class="col-md-6 form-group">
                              <label for="empcountry">Country</label>
                              <select class="form-select" name="employer_country" id="employer_country" value={customer.employer_country} onChange={handleInput }>
                              <option value="">Select Country</option>
                                  <option value="Canada">Canada</option>
                                  <option value="United States">United States</option>
                              </select>
                              <span class="text-danger">{error_list.employer_country}</span>
                          </div>
                          <div class="col-md-6 form-group">
                              <label for="employer_apart_or_suite">Apt/Suite/Other</label>
                              <input type="text" class="form-control" name="employer_apart_or_suite" id="employer_apart_or_suite" value={customer.employer_apart_or_suite} placeholder="Apt/Suite/Other"
                              onChange={handleInput }/>
                             <span class="text-danger">{error_list.employer_apart_or_suite}</span>
                          </div>
                          <div class="col-md-6 form-group">
                              <label for="employer_city">City</label>
                              <input type="text" class="form-control" name="employer_city" id="employer_city" value={customer.employer_city} placeholder="Enter Your City"
                              onChange={handleInput }/>
                               <span class="text-danger">{error_list.employer_city}</span>
                          </div>
                          <div class="col-md-6 form-group">
                              <label for="empstate">State</label>
                              <input type="text" class="form-control" name="employer_state" id="employer_state" value={customer.employer_state} placeholder="State"
                               onChange={handleInput }/>
                                 <span class="text-danger">{error_list.employer_state}</span>
                          </div>
                          <div class="col-md-6 form-group">
                              <label for="employer_postalcode">Postal Code</label>
                              <input type="text" class="form-control" name="employer_postalcode" id="employer_postalcode" value={customer.employer_postalcode} placeholder="Enter Your Postal Code"
                               onChange={handleInput }/>
                                 <span class="text-danger">{error_list.employer_postalcode}</span>
                          </div>
                          <div class="col-md-6 form-group">
                              <label for="job_title">Job Title</label>
                              <input type="text" class="form-control" name="job_title" id="job_title" value={customer.job_title} placeholder="Enter Your Job Title"
                               onChange={handleInput }/>
                                 <span class="text-danger">{error_list.job_title}</span>
                          </div>
                          {/* <div class="col-md-6 form-group">
                              <label for="monthly_income">Monthly Income</label>
                              <input type="text" class="form-control" name="monthly_income" id="monthly_income" value={customer.monthly_income} placeholder="Enter Your Monthly Income"
                                onChange={handleInput }/>
                                   <span class="text-danger">{error_list.monthly_income}</span>
                          </div> */}
                          <div class="col-md-6 form-group">
                              <label for="vehicle_type">Vehicle Type</label>
                              <input type="text" class="form-control" name="vehicle_type" id="vehicle_type" placeholder="Vehicle Type" value={customer.vehicle_type} 
                              onChange={handleInput }/>
                                 <span class="text-danger">{error_list.vehicle_type}</span>
                          </div>
                          <div class="col-md-12 form-group">
                              <label for="hear_about">How did you hear about us</label>
                              <input type="text" class="form-control" name="hear_about" id="hear_about" value={customer.hear_about} placeholder="How did you hear about us"
                              onChange={handleInput }/>
                              <span class="text-danger">{error_list.hear_about}</span>
                          </div>
                      </div>
                     
                          <button class="btn btn-sm btn_secondary"  onClick={update}>{loading?"Updating":"Update"}</button>
                     
                  </div>
              </div>
          </div>
      </div>

  </div>
</div>


</div>



    );
}
export default  EditCustomer;