import SideNav from "./SideNav";
import TopBar from "./TopBar";
import { useState, useEffect, useRef } from "react";
import Swal from 'sweetalert2';
import axios from 'axios';
import {Link, useParams, useNavigate} from "react-router-dom";
import ReactPaginate from 'react-paginate';
import { Chart } from 'react-chartjs-2';

function EditInventorySection3(){

    const API_URL = process.env.REACT_APP_API_URL;
    const LARAVEL_API_URL = process.env.REACT_APP_LARAVEL_API;
    const { inventory_section_id } = useParams();
    const [inventorySectionId, setInventorySectionId] = useState("");
    // const [inventory_section_id, setInventory_section_id] = useState(useParams().id);
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const [inventory2, setInventory2] = useState();
    const [inventory3, setInventory3] = useState([]);
    const getFormattedPrice = (price) => `$${price.toFixed(2)}`;
    const [editFlag, setEditFlag] = useState(0);
    const [error_list, setErrorList] = useState([]);
    const [imageURL, setImageURL] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
  
    const [inventoryId, setInventoryId] = useState();
    const [invoice_preset, setInvoice_preset] = useState([]);
    const [invoice_cart, setInvoice_cart] = useState([]);
    const [coverImage, setCoverImage] = useState([]);
    const [inventoryImages, setInventoryImages] = useState([""]);
    const [invoice_cart_by_id, setInvoice_cart_by_id] = useState([]);
   
    const [checkedoptionstable, setCheckedoptionstable] = useState([]);
    const [tablecheckbox, setTablecheckbox] = useState("");
    const isCheckedoptionstable = (item) =>checkedoptionstable.includes(item) ? "checked-item" : "not-checked-item";

    const [purchase_price, setPurchase_price] = useState();
    const [actual_cash_value, setActual_cash_value] = useState();

    const [invoice_preset_id, setInvoice_preset_id] =  useState("");
    const [item_name, setItem_name] =  useState("");
    const [price, setPrice] =  useState("");
    const [quantity, setQuantity] = useState(1);
    const [total_price, setTotal_price] = useState("");
    var total_cart_price = 0;
    // const [total_cart_price, setTotal_cart_price] = useState(0);
    const [total_invested, setTotal_invested] = useState("");

    const [loading, setLoading] = useState(false);
    const [watermark, setWatermark] = useState();
    const [header, setHeader] = useState("");
    const [footer, setFooter] = useState("");
  
    const [files, setFiles] = useState();
    const [mainImageURL, setMainImageURL] = useState();
    const [mainImage, setMainImage] = useState();
    const [previews, setPreviews] = useState([]);
    const [inventory_images, setInventory_images] = useState([]);
    const [success,setSuccess]= useState();
    const [fileState,setFileState]= useState(0);
    const [progress, setProgress] = useState(0);
    const fileInpurRef = useRef(null);
    const fileMainInpurRef = useRef(null);
    const [is_dragging, setIs_dragging] =  useState(false);
    const [is_draggingImages, setIs_draggingImages] =  useState(false);
    const [filepathCompressed, setFilepathCompressed] = useState([]);
    const [header_text, setHeader_text] = useState("");
    const [footer_text, setFooter_text] = useState(""); 
    const chartInstanceRef = useRef(null);


    const filteredResult = invoice_preset.filter((item) => {
      return item.item_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.price.toString().includes(searchQuery) || 
      item.quantity.toString().includes(searchQuery) ||
      (price * quantity).toString().includes(searchQuery) 
    });


    const handlePageChange = (selectedPage) => {
      setCurrentPage(selectedPage.selected);
    };

    const handleInput = (e) =>{
        setInvoice_cart_by_id({...invoice_cart_by_id, [e.target.name] : e.target.value})
      }
      const handleInventoryInput = (e) =>{
        setInventory3({...inventory3, [e.target.name] : e.target.value})
       
      }

    useEffect(() => {
        setIsLoading(true);
        axios.get(`${API_URL}/edit-inventory2/`+inventory_section_id ).then(res=>{
            if(res.data.status === 200){
               setInventory2(res.data.inventorySection2);
                setPurchase_price(res.data.inventorySection2.purchase_price);
                setActual_cash_value(res.data.inventorySection2.actual_cash_value);
                //console.log(res.data.inventorySection2);
                if(res.data.error){
                 
                  navigate('/inventorySection3/'+inventory_section_id);
                }  
            }
            else if(res.data.status === 404){
         //   Swal("Error",res.data.error,"error");
              //navigate.push('/view-inventory');
               //navigate('/inventorySection3/'+inventory_section_id);
              //  console.log('404 error',res.data.error);   
            }
            getInvoicePresetList();   
            getInvoiceCartList(inventory_section_id);   
            getInventory3List(inventory_section_id);
            getCoverImage(inventory_section_id);
            getInventoryImages(inventory_section_id);
            // saveInvoicecart();  
            
         });
        
         }
         
         , []);
    
         useEffect(() => {

          let total = 0;
          invoice_cart.forEach((item) => {
            total += item.price * item.quantity;
          });
      
          if (chartInstanceRef.current) {
           chartInstanceRef.current.destroy();
         }
         const profit = actual_cash_value - (Number(purchase_price) + total);
      
         // Create a new chart instance
         const ctx = document.getElementById('piechart_inventory').getContext('2d');
         chartInstanceRef.current = new Chart(ctx, {
           type: 'pie',
           data: {
             labels: ['Expenses', 'Purchase', 'Profit'],
             datasets: [
               {
                 data: [total, purchase_price, profit],
                 backgroundColor: ['#f0534a', '#0388fc', '#9fd79a'],
                 borderWidth: 2,
               }
             ]
           }
         });
      
         // Clean up the chart instance on unmount
         return () => {
           if (chartInstanceRef.current) {
             chartInstanceRef.current.destroy();
             chartInstanceRef.current = null;
           }
         };
          
        }, [invoice_cart]);
      
    
    
           function getInvoicePresetList()
          {
      
            axios.get(`${API_URL}/view-invoice-preset`).then(res=>{
              if(res.data.status === 200){
                 setInvoice_preset(res.data.invoicePreset);
                 setItem_name(res.data.invoicePreset.item_name);
                 setPrice(res.data.invoicePreset.price);
                }     
                else if(res.data.status === 404){
                }
               
             });       
          }

          function getInventory3List(inventory_section_id)
          {
            axios.get(`${API_URL}/edit-inventory3/`+inventory_section_id).then(res=>{
                 setInventory3(res.data.inventorySection3);
                //console.log(res.data.inventorySection3);
             });       
          }

          function saveInvoicecart(invoice_preset){
           
            setInventorySectionId(inventory_section_id);
            setInvoice_preset_id(invoice_preset.id);
            //console.log(invoice_preset.id);
            setItem_name(invoice_preset.item_name);
            setPrice(invoice_preset.item_name);
            setQuantity(1);
            setTotal_price(invoice_preset.price*quantity);

             const formData = new FormData()
    
            formData.append('inventoryId',inventory_section_id)
            formData.append('invoice_preset_id',invoice_preset.id)
            formData.append('item_name', invoice_preset.item_name)
            formData.append('price', invoice_preset.price)
            formData.append('quantity', quantity)    
            formData.append('total_price', invoice_preset.price*quantity) 
 
        
             axios.post(`${API_URL}/save-invoice-cart`, formData).then(res =>{
               getInvoiceCartList(inventory_section_id);
            //console.log(res.data.invoiceCart);
            //   Swal.fire({
            //     icon:"success",
            //     text:res.data.message
            //   }).then(function(){
            //     //   resetData();
            // });
            }).catch(function(error) {
              // if(error.response.status===422){         
              setErrorList(error.response.data.validate_err)
              // }
              // else{
                Swal.fire({
                  text:error.response.data.message,
                  icon:"error"
                })
              // }
            })
          }

          function getInvoiceCartList(inventory_section_id)
          {
            axios.get(`${API_URL}/view-invoice-cart-by-inventory-id/`+inventory_section_id).then(res=>{
                 setInvoice_cart(res.data.invoiceCart);
                //console.log(res.data.invoiceCart);
             });       
          }


          function getCoverImage(inventory_section_id)
          {
            axios.get(`${API_URL}/view-cover-image-by-inventory-id/`+inventory_section_id).then(res=>{
              if(res.data.status===200){

                setCoverImage(res.data.coverImage);
                //setMainImage(`${LARAVEL_API_URL}${res.data.coverImage.filepath}`);
                //setMainImageURL(`${LARAVEL_API_URL}${res.data.coverImage.filepath}`);
                setHeader_text(res.data.coverImage.header_text);
                setFooter_text(res.data.coverImage.footer_text);

                if(res.data.coverImage.watermark!=null){
                   setWatermark("watermark");
                   
                }

                if(res.data.coverImage.header_text!=null){
                   setHeader("header");
                   
                }
                if(res.data.coverImage.footer_text!=null){
                   setFooter("footer");
                 
                }
             

                const imageURL = `${LARAVEL_API_URL}${res.data.coverImage.filepath}`;
                const imageURLOriginal = `${LARAVEL_API_URL}${res.data.coverImage.filepath_original}`;
                const filename = new URL(imageURL).pathname.split('/').pop();
                const filenameOriginal = new URL(imageURLOriginal).pathname.split('/').pop();
                convertImageFromURLToFile(imageURL,filename);
                convertOriginalImageFromURLToFile(imageURLOriginal,filenameOriginal);
               
                //console.log(res.data.coverImage);
              }
else if(res.data.status===404)
{
  setIsLoading(false);  
}
          
             });       
          }

            async function convertImageFromURLToFile(imageURL, outputFileName) {
            try {
                const response = await fetch(imageURL);
    const blob = await response.blob();
    const file = new File([blob], outputFileName, { type: blob.type });
    // console.log('Converted file:', file);
    
    setMainImageURL(URL.createObjectURL(file));
          
            } catch (error) {
              console.error('Error converting main image:', error);
            }
          }

          async function convertOriginalImageFromURLToFile(imageURLOriginal, outputFileName) {
            try {
                const response = await fetch(imageURLOriginal);
    const blob = await response.blob();
    const file = new File([blob], outputFileName, { type: blob.type });

    // Example: Use the file as needed
     //console.log('Converted file:', file);
    setMainImage(file);
    
          
            } catch (error) {
              console.error('Error converting main image:', error);
            }
          }




          function getInventoryImages(inventory_section_id)
          {
            
        
            axios.get(`${API_URL}/view-inventory-images-by-inventory-id/`+inventory_section_id).then(res=>{
              if(res.data.status===200){
                setInventoryImages(res.data.inventoryImages);
                const filepathArray = (res.data.inventoryImages.filepath_compressed).split(',');
                const imageUrlArray = filepathArray.map(filepath => `${LARAVEL_API_URL}${filepath}`);
             
              base64Array(imageUrlArray);
async function imageUrlToBase64(imageUrl) {
console.log(imageUrl);
   const response = await fetch(imageUrl);
   console.log('Response status:', response.status);  // Log status code
    
   if (!response.ok) {
     throw new Error('Failed to fetch the image');
   }
   const blob = await response.blob();
   return new Promise((resolve, reject) => {
     const reader = new FileReader();
     reader.onloadend = () => resolve(reader.result);
     reader.onerror = reject;
     reader.readAsDataURL(blob);
   });
 }
 async function base64Array(imageUrlArray) {
// Convert each image URL to base64
setFiles(previews.map((base64, index) => {
   return base64ToFile(base64, `image_${index + 1}`);
 }))
  

const base64Array = await Promise.all(imageUrlArray.map(imageUrl => imageUrlToBase64(imageUrl)));
//  console.log(base64Array);
setPreviews(base64Array);
setIsLoading(false);  

 }



// Function to convert a base64 string to a file
function base64ToFile(base64String, filename) {
   const arr = base64String.split(",");
   const mime = arr[0].match(/:(.*?);/)[1];
   const extension = mime.split('/')[1];
   const bstr = atob(arr[1]);
   let n = bstr.length;
   const u8arr = new Uint8Array(n);
   while (n--) {
     u8arr[n] = bstr.charCodeAt(n);
   }
   return new File([u8arr],`${filename}.${extension}`, { type: mime });
     }
              }

           else if(res.data.status===404){
  setIsLoading(false);  
           }
      
            });     
            
           
          }

          async function convertOtherImageFromURLToFile(otherImageURL, outputFileName) {
           
            try {
              const response = await fetch(otherImageURL);
              const blob = await response.blob();
              const file = new File([blob], outputFileName, { type: blob.type });
              // Example: Use the file as needed
              // console.log('Converted file:', file);
              setFiles(file);
             // previews.push(URL.createObjectURL(file));

             
             // setPreviews(URL.createObjectURL(file));
               
               
             //convertFileToBase64(file);
                  
            } catch (error) {
              console.error('Error converting other image:', error);
            }
          
          }


          
       




          const deleteInvoiceCart = (e,id) =>{
            e.preventDefault();
            var buttonClicked = window.confirm("Are you sure you want to delete invoice item?");
            if(buttonClicked == true){
            // const thisClicked = e.currentTarget;
            // thisClicked.innerText = "Deleting";
           
            axios.delete(`${API_URL}/delete-invoice-cart/`+id).then(res => {    
                getInvoiceCartList(inventory_section_id);
             
        //   Swal.fire({
        //           icon:"success",
        //           text:res.data.message
        //         }).then(function(){
        //             //  thisClicked.closest("tr").remove();  
                    
        //       });
            })
          }
           
    
           }


          function tabletotal(event, item, totalprice){

            var updatedList = [...checkedoptionstable];
            if (event.target.checked) {
              updatedList = [...checkedoptionstable, event.target.value];
            //   setTotal(total + totalprice) ;
              saveInvoicecart(item);

            // console.log(total);
            } else {
              updatedList.splice(checkedoptionstable.indexOf(event.target.value), 1);
            //   setTotal(total - totalprice) ;
          
            }
            setCheckedoptionstable(updatedList);  
            // console.log(checkedoptionstable);
        
            setTotal_invested(Number(purchase_price) + Number(actual_cash_value) + total_cart_price);
            // console.log(total_invested);

        }

        const handleDecrement = (invoice_cart_id) =>{
             setInvoice_cart(invoice_cart=>invoice_cart.map((item)=>
             invoice_cart_id === item.id ? {...item, quantity : item.quantity - (item.quantity > 1 ? 1:0)} : item ));
             updateInvoiceCartQuantity(invoice_cart_id,"dec");
        }

        const handleIncrement = (invoice_cart_id) =>{

            setInvoice_cart(invoice_cart=>invoice_cart.map((item)=>
            invoice_cart_id === item.id ? {...item, quantity : item.quantity + (item.quantity < 10 ? 1:0)} : item ));
            updateInvoiceCartQuantity(invoice_cart_id,"inc");
        }


  function updateInvoiceCartQuantity(invoice_cart_id,scope){
    
     axios.put(`${API_URL}/update-invoice-cart/${invoice_cart_id}/${scope}`).then(res =>{
   
    
        // Swal.fire({
        //           icon:"success",
        //           text:res.data.message
        //         }).then(function(){
        //             // setErrorList([]);
        //             // navigate('/setting-invoice-preset');
        //         //   navigate('/InventorySection2'/${inventory_section_id});
                
        //       });
              }).catch(function(error) {
                 // if(error.response.status===422){         
                 setErrorList(error.response.data.validate_err);
                 // }
             //    else{
                  Swal.fire({
                 
                    text:error.response.data.message,
                    icon:"error"
                  })
             //    }
              })
           
              

  }



const editInvoiceCart = (item) =>{
    setEditFlag(item.id);
// console.log(`${API_URL}/edit-invoice-cart/` +item.id);
    axios.get(`${API_URL}/edit-invoice-cart/` +item.id ).then(res=>{
        if(res.data.status === 200){
    
            setInvoice_cart_by_id(res.data.invoiceCart);
            setInventoryId(res.data.invoiceCart.inventory_section_id);
            setItem_name(res.data.invoiceCart.item_name);
            setPrice(res.data.invoiceCart.price);
            setQuantity(res.data.invoiceCart.quantity);
            setTotal_price(res.data.invoiceCart.total_price);
       
        }
        else if(res.data.status === 404){
       Swal("Error",res.data.error,"error");
          //navigate.push('/view-inventory');
          console.log(res.data.error)
        }
   
      
     });

}

function onDragOver(event){
    event.preventDefault();
    setIs_draggingImages(true);
    event.dataTransfer.dropEffect = "copy";
   }

   

   function onDragLeave(event){
   event.preventDefault();
   setIs_draggingImages(false);
   }

   function onDrop(event){
    event.preventDefault();
    setIs_draggingImages(false);
    setFiles(event.dataTransfer.files);
const selectedFiles = Array.from(event.dataTransfer.files);
    // Generate previews for each selected file
    const filePreviews = selectedFiles.map((file) => {
        const reader = new FileReader();
  
        reader.onload = () => {
          // Create a preview URL for the file
          const previewUrl = reader.result;
          // Update the previews state
          setPreviews((prevPreviews) => [...prevPreviews, previewUrl]);
        };
  
        reader.readAsDataURL(file);
      });


   }


   function onDragOverImage(event){
    event.preventDefault();
    setIs_dragging(true);
    event.dataTransfer.dropEffect = "copy";

   }

   function onDragLeaveImage(event){
   event.preventDefault();
    setIs_dragging(false);
   }

   function onDropImage(event){
    event.preventDefault();
    setIs_dragging(false);
     const files = event.dataTransfer.files[0];
     setMainImage(files);
     setMainImageURL(URL.createObjectURL(event.dataTransfer.files[0]));
   
   }

   function deleteMainImage(){
    setMainImage(null);

   }

   function deleteImage(index){
      const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
    setPreviews(previews.filter((_, i) => i !== index));


   }

   function selectMainFile(){
    fileMainInpurRef.current.click();

   }

function selectFiles(){
fileInpurRef.current.click();

}


function onMainFileSelect(event){
    const file = event.target.files[0];
    // setMainImage(event.target.files[0]);
    if(file){
      setMainImage(event.target.files[0]);
      setMainImageURL(URL.createObjectURL(event.target.files[0]));
    }
    
   }

   function onFileSelectImages(event){
    setFiles(event.target.files);
 
    
//preview
    const selectedFiles = Array.from(event.target.files);
    // Generate previews for each selected file
    const filePreviews = selectedFiles.map((file) => {
        const reader = new FileReader();
  
        reader.onload = () => {
          // Create a preview URL for the file
          const previewUrl = reader.result;
          // Update the previews state
          setPreviews((prevPreviews) => [...prevPreviews, previewUrl]);
        };
  
        reader.readAsDataURL(file);

      });
    

  }


  
   function uploadImages(){
   
    setLoading(true); 
    setFileState(1);

    function base64ToFile(base64, filename) {
        const arr = base64.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const extension = mime.split('/')[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        
        return new File([u8arr],`${filename}.${extension}`, { type: mime });
      }
      
       
      const files = previews.map((base64, index) => {
        return base64ToFile(base64, `image_${index + 1}`);
      });   
   
      //console.log(previews,files);  
    
    if(files == null){
        setLoading(false); 
    }
    // console.log("file", files);

    const formData = new FormData()

     formData.append('inventory_section_id', inventory_section_id) 
     formData.append('mainImage', mainImage);
     formData.append('watermark', watermark);
     formData.append('header', header);
     formData.append('footer', footer);
     formData.append('header_text', header_text);
     formData.append('footer_text', footer_text);
     console.log(header,footer)

    if(files !=null){
      for(let i=0;i<files.length;i++){
        formData.append('inventory_section_id', inventory_section_id)    
        formData.append(`file${i+1}`,files[i]);
    }
}
    axios.post(`${API_URL}/save-inventory-images`, formData).then(res =>{
     
     setSuccess('Images uploaded successfully!!');
           setLoading(false);
    
     }).catch(function(error) {
       // if(error.response.status===422){         
    //    setErrorList(error.response.data.validate_err)
       // }
       // else{
        //  Swal.fire({
        //   text:"Please upload header and footer image under Image Preset Menu",
        //   text:error.response.data.message,
        //    icon:"error"
        //  })
         setLoading(false); 
       // }
     })


   }

   const handleCheckOptionsWatermark = (event) => {

   
    if (event.target.checked) {
      
      axios.get(`${API_URL}/view-image-preset`).then(res => {
        if (res.data.status === 200 && res.data.imagePreset[0].filepath_watermark != null) { 
          setWatermark(event.target.value);
        } else  {
          Swal.fire({
            text: `Please upload watermark image in Image Preset Menu.`,
            icon: "warning"
          }).then(() => {
            event.target.checked = false;
            setWatermark("");
        });
        }
      });
    } 
    else{
        setWatermark("");       
    }
  };   
  
  
  const handleCheckHeader= (event) => {
    if (event.target.checked) {
      axios.get(`${API_URL}/view-image-preset`).then(res => {
        if (res.data.status === 200 && res.data.imagePreset[0].filepath_header != null) { 
          setHeader(event.target.value);
        } else  {
          Swal.fire({
            text: `Please upload header image in Image Preset Menu.`,
            icon: "warning"
          }).then(() => {
            event.target.checked = false;
            setHeader("");
        });
        }
      });

    } 
     
    else{
        setHeader("");     
    }
  
  };   

  const handleCheckFooter = (event) => {
    if (event.target.checked) {
      axios.get(`${API_URL}/view-image-preset`).then(res => {
        if (res.data.status === 200 && res.data.imagePreset[0].filepath_footer != null) {
          setFooter(event.target.value);
        } else{
          Swal.fire({
            text: `Please upload footer image in Image Preset Menu.`,
            icon: "warning"
          }).then(() => {
            event.target.checked = false;
            setFooter("");
        });
      }
    });
  }
    else{
        setFooter("");       
    }
  
  };   

  const convertToBase64 = async (previewUrl) => {
    const response = await fetch(previewUrl);
    const blob = await response.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };



  const handleImageClick = async (previewUrl) => {
    const base64Data = await convertToBase64(previewUrl);
    Swal.fire({
      imageUrl: base64Data,
      imageAlt: 'Image',
      showCloseButton: true,
      showConfirmButton: false,
    //   imageWidth: 1024,
    //   imageHeight: 768,
    });
  };

  const handleCoverImageClick = async (mainImageURL) => {
   // const base64Data = await convertToBase64(previewUrl);
    Swal.fire({
      imageUrl: mainImageURL,
      imageAlt: 'Image',
      showCloseButton: true,
      showConfirmButton: false
    });
  };

  async function next(event){
    event.preventDefault();
   
    axios.get(`${API_URL}/edit-inventory3/`+inventory_section_id).then(res=>{
      if(res.data.status==200){
        update();
      }
      else
      {
        save();  
      }
  }); 
  
  }


  async function save()
  {
    // event.preventDefault();
    setIsLoading(true);

    const formData = new FormData()

      formData.append('inventory_section_id', inventory_section_id)
      formData.append('list_price', inventory3.list_price)
      formData.append('sale_price', inventory3.sale_price)
      formData.append('msrp', inventory3.msrp)
      
      try {
        const res = await axios.post(`${API_URL}/save-inventory3`, formData);
        if (res.data.status === 200) {
            // Success
            setErrorList([]);
            navigate('/view-inventory');
        }
    } catch (error) {
        setIsLoading(false);
        if (error.response && error.response.status === 422) {
            // Validation errors
            setErrorList(error.response.data.validate_err || []);
        } else {
            // Handle other errors (e.g., network issues)
            console.error('Error saving inventory:', error);
        }
    }

 }


  async function update()
  {
  //  event.preventDefault();
setIsLoading(true);
      const data = inventory3;
       await axios.put(`${API_URL}/update-inventory3/` +inventory_section_id ,data).then(res =>{

// Swal.fire({
//           icon:"success",
//           text:res.data.message
//         }).then(function(){
            setErrorList([]);
           navigate('/view-inventory' );
       
    //   });
      }).catch(function(error) {
        setIsLoading(false);
         // if(error.response.status===422){         
         setErrorList(error.response.data.validate_err);
         // }
     //    else{
          Swal.fire({
         
            text:error.response.data.message,
            icon:"error"
          })
     //    }
      })
   
      }

    
       
  
    return(
        
 
        <div>
            
           

            <SideNav/>
            <TopBar/>
            
            {isLoading?
     <div className="loader-container" style ={{backgroundColor: "rgba(0, 0, 0, 0.5)", position: "fixed",top: "0",left: "0",width: "100%",height: "100%",display: "flex",justifyContent: "center",alignItems: "center",zIndex: "9999"}}>
    <div class="d-flex justify-content-center">
  <div class="spinner-border" role="status">
  </div>
  </div>
</div>:null}


            <div class="d-flex">

<div class="right_panel position create_inventory h-100" id="right_panel">

{/* <!-- Main Title --> */}
    <div class="main_title"  data-aos-delay="" data-aos-duration="1200">
        <h1><i class="bi bi-plus-lg h1"></i> Create New Inventory</h1>
       
    </div>
   

    <div class="content_wrapper pt-0">


            <div class="row" id="inventory_section3">
            <div class="col-md-12">
                <div class="vm_content_box_1 select_option">
                    <div class="title d-flex justify-content-between">
                        <h2><i class="bi bi-currency-dollar"></i> Cost</h2>
                        {/* <Link class="btn_secondary text-white my-1 py-2" to={`/CreateInvoice`}>+ Create Invoice</Link> */}
                            {/* <button class="btn_secondary text-white my-1 py-2" ></button> */}
                       
                    </div>
                    <div class="content pt-3 pb-4">
                        <div class="row pb-2">
                          
                        <div class="col-md-9 form-group" style={{marginTop:"8px"}}>
                                <div class="d-flex input_wrapper">
                                <div ><label>Show   <select name="example_length" aria-controls="example" value={perPage} onChange={(e) => {setCurrentPage(0);setPerPage(Number(e.target.value))}}>
                                    <option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option>
                                  </select>  entries</label>
                                  </div>
                            
                 
                                </div>
                            </div>

                            <div class="col-md-3 form-group">
                                <div class="d-flex input_wrapper">
                                    <div class="icon_wrapper">
                                        <i class="bi bi-search icon"></i>
                                    </div>
                                    <input class="form-control" name="searchQuery" id="searchQuery" placeholder="Search Cost Items" type="text" 
                                    value={searchQuery}  onChange={(e) => {setSearchQuery(e.target.value)}}/>
                                </div>
                            </div>
                        </div>


  <><table class="display dataTable dtr-inline" style={{ width: "100%" }}>
                          <thead>
                            <tr>
                              <th>Select</th>
                              <th>Name</th>
                              <th>Price</th>
                              <th>Quantity</th>
                              <th>Total</th>
                            </tr>
                          </thead>



                          <tbody>

                          {filteredResult.length == 0 ?
        <table class="display dataTable dtr-inline">
        <tbody>
          <tr>
            {/* <td colSpan="4" style={{textAlign: "center"}}>{message}</td> */}
            <div class="row" style={{margin: "10px -900px 10px 10px"}}>
                       <p  valign="top" class="odd" style={{ textAlign: "center" }} >No records found</p> 
                       </div>
          </tr>
        </tbody>
      </table>
      :    null }
                            {filteredResult.slice((currentPage * perPage), ((currentPage * perPage) + perPage)).map((item, index) => (


                              <tr key={index}>
                                <td>
                                  <div style={{ margin: "5px 0px 0px 0px" }}>
                                    <input type="checkbox" checked={invoice_cart.find(u => u.invoice_preset_id === item.id)} onChange={(event) => {
                                      setTablecheckbox(event.target.value); tabletotal(event, item, item.totalprice);
                                    } } />
                                    <label for="leatherInterior" className={isCheckedoptionstable(item)}></label>
                                  </div>
                                </td>

                                <td>
                                  <p>{item.item_name}</p>


                                </td>
                                <td>
                                  <p>{'$'}{item.price}</p>
                                </td>
                                <td>

                                  <p>{item.quantity}</p>


                                </td>


                                <td>

                                  <p>{'$'}{item.price * item.quantity}</p>
                                </td>
                              </tr>



                            ))}
                          </tbody>


                          <tfoot>
                            <tr>
                              <th></th>
                              <th>Name</th>
                              <th>Price</th>
                              <th>Quantity</th>
                              <th>Total</th>
                            </tr>
                          </tfoot>

                        </table><div class="col-md-3" style={{ float: "left", padding: "20px 0px 0px 0px" }}>
                            {filteredResult.length > 0 && `Showing 1 to ${Math.min(perPage, filteredResult.length)} of ${filteredResult.length} entries`}
                          </div><div class="col-md-9" style={{ float: "right" }}>

                            <ReactPaginate style={{ float: "right" }}
                              previousLabel={"< Prev"}
                              nextLabel={"Next >"}
                              breakLabel={'...'}
                              pageCount={Math.ceil(filteredResult.length / perPage)}
                              onPageChange={handlePageChange}
                              containerClassName={"pagination pagination-sm justify-content-center custom-pagination"} // Bootstrap pagination class
                              disabledClassName={"disabled"}
                              breakClassName={['page-item']}
                              breakLinkClassName={'page-link'}
                              pageClassName={'page-item'}
                              pageLinkClassName={'page-link'}
                              previousClassName={'page-item'}
                              previousLinkClassName={'page-link'}
                              nextClassName={'page-item'}
                              nextLinkClassName={'page-link'}
                              activeClassName={['active']} />


                          </div></>



                        


                      
                        <div class="title d-flex justify-content-between" style={{padding:"5px", marginTop:"60px"}}>
                        <h2><i class="bi bi-currency-dollar"></i> Invoice List</h2>                  
                    </div>
      


<div className="table-responsive">
                        <table  class="display dataTable dtr-inline" style={{tableLayout: "fixed",width:"100%"}}>
                            <thead>
                                <tr>
                                    <th>S.no</th>
                                    <th>Name</th>
                                    <th>Price</th>
                                    <th>Quantity</th>  
                                    <th>Total</th>
                                    <th>Action</th>
                                </tr>
                            </thead>



                            <tbody>


                            {invoice_cart.length == 0 ?
        <table class="display dataTable dtr-inline">
        <tbody>
          <tr>
            <div class="row" style={{margin: "10px -950px 10px 10px"}}>
                       <p  valign="top" class="odd" style={{ textAlign: "center" }} >No records found</p> 
                       </div>
          </tr>
        </tbody>
      </table>
      :    null }
                  

                            {invoice_cart.map((item, index) => {
                                  total_cart_price += item.price*item.quantity;
                             return(
                                <tr key={index}>
                                    
                                    <td>
                                        <p>{index+1}</p>
                                    </td>
                                    
                                    <td>
                                        <p>{item.item_name}</p>

                                    </td>
                                    <td>
                                        <p>{'$'}{item.price}</p>
                                    </td>
                                    
                               <td >

                               {/* <div class="row">
                                        <div class="col-md-8" > */}
                                     <div class="input-group" style={{width:"fit-content"}} > 
                                     <button type="button" onClick={() => handleDecrement(item.id)} class="input-group-text">-</button>
                                     <div class="form-control text-center" >{item.quantity}</div>
                                     <button type="button" onClick={() => handleIncrement(item.id)} class="input-group-text">+</button> 
                                     </div>
                                     {/* </div>
                                     </div> */}

                               </td>

                                    
                                  
                                    <td>
                                  
                                       <p>{'$'}{item.price*item.quantity}</p>
                                    </td>
                               <td>
                               <button  onClick={(e) => deleteInvoiceCart(e,item.id)}  class="btn btn-sm btn_secondary" 
                                    style={{background:"red",color:"white"}}><i class="bi bi-trash3"></i> Delete</button>   
                               </td>
                                  
                                </tr>
                             )
                             
                                    })} 
                            </tbody>

                       
                              <tfoot>
                                <tr>
                                <th>S.no</th>
                                    <th>Name</th>
                                    <th>Price</th>
                                    <th>Quantity</th>  
                                    <th>Total</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot> 
                        </table>

                        </div>


                        <div class="row">
                        <div class="col-md-4"></div>
                          
                          <div class="col-md-2 pt-2">
                              <canvas id="piechart_inventory" height={455} style={{display: "block", boxSizing: "borderBox", height: "182px", width: "182px"}} width={455}/>
                             
                          </div>
                          <div class="col-md-1"></div> 
                            <div class="col-md-5 pt-5">
                                <div class="d-flex justify-content-end">
                                    <div>

                                    </div>
                                    <div>
                                        <div class="d-flex justify-content-end">
                                            <h1>Vehicle Purchase Price: </h1>
                                            <h1 class="vm_font_bold ps-md-3 text-end">{'$'}{purchase_price}</h1>
                                        </div>
                                        <div class="d-flex justify-content-end pt-3">
                                            <h1>Actual Cash Value: </h1>
                                            <h1 class="vm_font_bold ps-md-3 text-end">{'$'}{actual_cash_value}</h1>
                                        </div>
                                        <div class="d-flex justify-content-end pt-3">
                                            <h1>Additional Expenses Total: </h1>
                                            <h1 class="vm_font_bold ps-md-3 text-end">{'$'}{total_cart_price.toFixed(2)}</h1>
                                        </div>
                                        {(purchase_price&&actual_cash_value&&total_cart_price!=null)? 
                                       <div class="d-flex justify-content-end pt-3">
                                       <h1 class="text-danger">Total Invested: </h1>
                                       <h1 class="vm_font_bold ps-md-3 text-danger text-end">{'$'}{(Number(purchase_price) + total_cart_price).toFixed(2)}</h1>
                                   </div>
                                        :null}
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row pt-5">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="listprice">List Price</label>
                                    <div class="d-flex input_wrapper">
                                        <div class="icon_wrapper">
                                            <i class="bi bi-currency-dollar text-white"></i>
                                        </div>
                                        <input type="text" class="form-control" name="list_price" id="list_price" placeholder="$0.00"  value={inventory3 && inventory3.list_price}  onChange={handleInventoryInput }/>
                                    </div>
                                </div>
                                <span class="text-danger">{error_list.list_price}</span>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="saleprice">Sale Price</label>
                                    <div class="d-flex input_wrapper">
                                        <div class="icon_wrapper">
                                            <i class="bi bi-currency-dollar text-white"></i>
                                        </div>
                                        <input type="text" class="form-control" name="sale_price" id="sale_price" placeholder="$0.00" value={inventory3 && inventory3.sale_price}  onChange={handleInventoryInput}/>
                                    </div>
                                </div>
                                <span class="text-danger">{error_list.sale_price}</span>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="msrp">MSRP</label>
                                    <div class="d-flex input_wrapper">
                                        <div class="icon_wrapper">
                                            <i class="bi bi-currency-dollar text-white"></i>
                                        </div>
                                        <input type="text" class="form-control" name="msrp" id="msrp" placeholder="$0.00" value={inventory3 && inventory3.msrp}  onChange={handleInventoryInput }/>
                                    </div>
                                </div>
                                <span class="text-danger">{error_list.msrp}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 pt-4">
                <div class="vm_content_box_1">
                    <div class="title">
                        <h2><i class="bi bi-patch-check"></i> Warranty</h2>
                    </div>
                    <div class="content pt-3">
                        <h5 class="vm_font_bold">Factory Warranty</h5>
                        <div class="form-group pt-2">
                            <div class="form-check form-switch d-flex align-items-center">
                                <input class="form-check-input" type="checkbox" role="switch" name="fact_warranty" id="fact_warranty" value="Factory Warranty"/>
                                <label class="form-check-label pb-0 ms-3" for="fact_warranty">Does this vehicle have a factory warranty?</label>
                            </div>
                        </div>

                        <h4 class="vm_font_bold">After Market Warranty</h4>
                        <div class="form-group pt-2">
                            {/* <!-- <label for="listprice">Search Warranties</label> --> */}
                            <div class="d-flex input_wrapper">
                                <div class="icon_wrapper">
                                    <i class="bi bi-search text-white"></i>
                                </div>
                                <input type="text" class="form-control" name="search_warranties" id="search_warranties" placeholder="Search Warranties"/>
                            </div>
                        </div>

                        <div class="highlightbox_1">
                            <div class="highlightbox_1_title">Dealer Warranty</div>
                            <div class="highlightbox_1_wrapper px-3 pb-2 pt-2">
                                <p>All safety related work as approved by Car Loan masters to be completed at dealer designated service center only</p>
                            </div>
                            <div class="px-3 pb-2 vm_font_bold">
                                <i class="bi bi-calendar-check"></i> <span class="ps-2">30 Days</span>
                            </div>
                            <div class="px-3 pb-2 vm_font_bold">
                                <i class="bi bi-speedometer"></i> <span class="ps-2">1500 Km</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 pt-4">
                <div class="vm_content_box_1 h-100">
                    <div class="title">
                        <h2><i class="bi bi-card-image"></i> Media</h2>
                    </div>

                    <div class="content pt-3">
                    <div class="form-group pt-3 pb-2 d-md-flex">
                    <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" role="switch" name="watermark" id="watermark" value="watermark"checked={watermark ? true : false}
                              onChange={handleCheckOptionsWatermark }/>
                                <label class="form-check-label" for="watermark">Add watermark to the image</label>
                            </div>

                            <div class="form-check form-switch ms-md-3 pt-3 pt-md-0">
                                <input class="form-check-input" type="checkbox" role="switch" name="header" id="header" value="header" checked={header ? true : false}
                              onChange={handleCheckHeader}/>
                                <label class="form-check-label" for="header">Add Header</label>
                            </div>

                            <div class="form-check form-switch ms-md-3 pt-3 pt-md-0">
                                <input class="form-check-input" type="checkbox" role="switch" name="footer" id="footer" value="footer" checked={footer ? true : false}
                              onChange={handleCheckFooter}/>
                                <label class="form-check-label" for="footer">Add Footer</label>
                            </div>
                            </div>

                           
                            {/* {header?<div class="form-group pt-2">
                          
                          <input class="form-control" name="header_text" value={header_text} onChange={(event) => { setHeader_text(event.target.value); } } id="header_text" placeholder="Enter Header Text" type="text"/>
                          </div>:null}  
                          
                          {footer?<div class="form-group pt-2">
                          <input class="form-control" name="footer_text" value={footer_text} onChange={(event) => { setFooter_text(event.target.value); } } 
                          id="footer_text" placeholder="Enter Footer Text" type="text"/>
                          </div>:null} */}

                         

                     <div  onDragOver={onDragOverImage} onDragLeave={onDragLeaveImage} onDrop={onDropImage} class="border d-flex justify-content-center align-items-center h-100 highlightbox_1 pb-3 pt-3">
                         <div >
                             <div class="d-flex justify-content-center">
                                 <i class="bi bi-upload display-6"></i>
                             </div>
                             <h4 class="text-center">Upload Cover Image</h4>
                             { is_dragging 
                              ? (<span   class="text-center" className="select">Drop images here</span>) 
                             :  ( <> Drag & Drop image here or {" "}
                                 <span style={{color: "deepskyblue", textDecorationLine: "underline"}} class="text-center" className="select" role="button" onClick={selectMainFile}>browse</span>
                                 </>
                            )}
                          
                            
                          <input  type="file"  ref={fileMainInpurRef} onChange={onMainFileSelect} class="d-none" ></input>      
                         </div>
                     </div>
                   
                   
<div className="container" style={{maxHeight : "200px",overflowY: "auto", display:"flex"}} >
{mainImageURL && 
<div style={{position:"relative",marginRight: "5px",marginBottom: "8px",height: "75px", width: "75px"}} className="image" >  
    <span  style={{position:"absolute",cursor: "pointer",top: "-2px",right: "2px", fontSize: "20px", color:"limegreen"}}
 className="delete" onClick={() => deleteMainImage()}></span>
    <img loading="lazy" onClick={() => handleCoverImageClick(mainImageURL)}  style={{width: "100%", height:"100%", borderRadius: "5px"}} src={mainImageURL} alt={mainImageURL}/>
   

  </div>
}
 
</div>

{fileState==1 && mainImage == null ?<span style={{padding:"5px"}} class="text-danger">{'Please upload Cover Image'}</span>:null}
{/* <button  class="btn_secondary text-white my-1 py-2" type="button" onClick={uploadMainImage} >Upload</button> */}



</div>

<div class="content pt-3">
                     
                     <div onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onDrop} class="border d-flex justify-content-center align-items-center h-100 highlightbox_1 pb-3 pt-3">
                         <div >
                             <div class="d-flex justify-content-center">
                                 <i class="bi bi-upload display-6"></i>
                             </div>
                             <h4 class="text-center">Upload other Images</h4>
                             { is_draggingImages
                              ? (<span   class="text-center" className="select">Drop images here</span>) 
                             :  ( <> Drag & Drop image here or {" "}
                                 <span style={{color: "deepskyblue", textDecorationLine: "underline"}} class="text-center" className="select" role="button" onClick={selectFiles}>browse</span>
                                 </>
                            )}
                          
                            
                          {/* <input name="images[]" type="file" multiple  ref={fileInpurRef} onChange={onFileSelect} class="d-none" />  */}
                         <input  type="file"  ref={fileInpurRef} multiple onChange={onFileSelectImages}  class="d-none" />   

                         </div>
                     </div>

                                     <div className="container"  style={{ maxHeight: "200px", overflowY: "auto", display: "flex" }}>
                                    
                                         {previews.map((previewUrl, index, file) => (
                                             <div key={index} style={{ position: "relative", marginRight: "5px", marginBottom: "8px", height: "75px", width: "75px" }}  >
                                                <span  style={{position:"absolute",cursor: "pointer",top: "-2px",right: "2px", fontSize: "20px", color:"limegreen"}}
                                                className="delete" onClick={() => deleteImage(index)}>&times;</span>
                           <img loading="lazy" onClick={() => handleImageClick(previewUrl)}  style={{ width: "100%", height: "100%", borderRadius: "5px" }}  src={previewUrl} alt={`Preview ${index}`} />       
                                         
                                             </div>
                                         ))}
                                     </div>
     


                                  {/* {progress > 0 && <progress value={progress} max="100"  style={{background: "green", width: "-webkit-fill-available"}}/>} */}
                                     <button  class="btn_secondary text-white my-1 py-2"  onClick={uploadImages}>{loading ? "Uploading..." : "Upload"}</button>
                                     {fileState==1 && files ==null?<span style={{padding:"15px"}} class="text-danger">{'Please upload other images'} </span>:<span style={{padding:"5px"}} class="text-success">{loading?null:success} </span>} 
                                      

</div>
                   


                </div>
            </div>

            {/* <!-- Next Button --> */}
            <div class="d-flex justify-content-md-start position-fixed bottom-0 vm_zindex_99 w-100 fixed_inv_btn">
            <div class="bg-dark px-4 start-0 end-0 pt-2 pb-2 vm_font_semi_bold text-white text-uppercase vm_cursor_pointer" >
                <Link to ={`/edit-inventorySection2/${inventory_section_id}`} style={{color:"white"}}>
                    <i class="bi bi-arrow-left-short"></i> Back</Link>
                </div>
                <div class="vm_bg_secondary px-4 start-0 end-0 pt-2 pb-2 vm_font_semi_bold text-white text-uppercase vm_cursor_pointer" onClick={next} >
                    Next <i class="bi bi-arrow-right-short"></i>
                </div>
            </div>
        </div> 

        </div>
        </div>
        </div>

                                         
        </div>
    )
}
export default EditInventorySection3;