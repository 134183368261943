import { useState, useEffect } from "react";
import SideNav from "./SideNav";
import TopBar from "./TopBar";
import Swal from 'sweetalert2';
import axios from 'axios';
import {Link, useParams, useNavigate} from "react-router-dom";



function EditInventorySection2(){
    
    const API_URL = process.env.REACT_APP_API_URL;
    const [inventory, setInventory] = useState([]);
//         {
// inventory_section_id:'',
// package_options:'',
// purchased_date:'',
// received_date:'',
// delivered_date:'',
// recon_completed_date:'',
// title_received:'',
// ownership_status:'',
// purchaser_type:'',
// purchaser_name:'',
// company_name:'',
// rin_number:'',
// phone_no:'',
// mobile_no:'',
// email:'',
// address:'',
// apart_or_suite:'',
// city:'',
// province:'',
// postal_code:'',
// country:'',
// purchase_price:'',
// actual_cash_value:'',
// discount:'',
// tax:'',



//         }
    // );
    const [updateCheckbox, setUpdateCheckbox] = useState(0);
    const { id } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    
    const [checkedoptions, setCheckedoptions] = useState([]); 
    const [checkedoptionsTechnology, setCheckedoptionsTechnology] = useState([]);
    const [error_list, setErrorList] = useState([]);
   
    const isCheckedoptions = (item) =>checkedoptions.includes(item) ? "checked-item" : "not-checked-item";
    const isCheckedoptionsTechnology = (item) =>checkedoptionsTechnology.includes(item) ? "checked-item" : "not-checked-item";
    const isCheckedoptionsPackage = (item) =>package_options.includes(item) ? "checked-item" : "not-checked-item";

    const checklistoptionstax = ["Tax Exempt", "Tax Override"];
    const [checkedoptionstax, setCheckedoptionstax] = useState([]);
    const isCheckedoptionstax = (item) =>checkedoptionstax.includes(item) ? "checked-item" : "not-checked-item";
  
    const checklistoptions = ["Leather Interior", "Panaromic Sunroof", "Luxurious Package", "Two Tone Paint Package", "Executive Package","Fully Loaded","Sport Package", "Premium Package"];
    const technologyoptions = ["Adaptive Cruse Control", "Aux In", "Blind Spot Monitor","Android Audio","Automatic Light", "Apple Car Play","Birds Eye View Camera"];
  

  const [package_options, setPackage_options] = useState("");
  const [technology_options, setTechnology_options] = useState("");
  const [tax_type, setTax_type] = useState("");
//   const [purchased_date, setPurchased_date] = useState(new Date());
//   const [received_date, setReceived_date] = useState(new Date());
//   const [delivered_date, setDelivered_date] = useState(new Date());
//   const [recon_completed_date, setRecon_completed_date] = useState(new Date());
//   const [title_received, setTitle_received] = useState(new Date());
//   const [ownership_status, setOwnership_status] = useState("");
//   const [purchaser_type, setPurchaser_type] = useState("");
//   const [purchaser_name, setPurchaser_name] = useState("");
//   const [company_name, setCompany_name] = useState(""); 
//   const [rin_number, setRin_number] = useState("");
//   const [phone_no, setPhone_no] = useState("");
//   const [mobile_no, setMobile_no] = useState("");
//   const [email, setEmail] = useState("");
//   const [address, setAddress] = useState("");
//   const [apart_or_suite, setApart_or_suite] = useState("");
//   const [city, setCity] = useState("");
//   const [province, setProvince] = useState("");
//   const [postal_code, setPostal_code] = useState("");
//   const [country, setCountry] = useState("");

//   const [purchase_price, setPurchase_price] = useState();
//   const [actual_cash_value, setActual_cash_value] = useState();
//   const [discount, setDiscount] = useState();
//   const [tax, setTax] = useState();


  const handleInput = (e) =>{
    setInventory({...inventory, [e.target.name] : e.target.value})
  }

  

    useEffect(() => {
        setIsLoading(true);
        axios.get(`${API_URL}/edit-inventory2/`+id ).then(res=>{
           if(res.data.status === 200){
              setInventory(res.data.inventorySection2);
               const checkedoptions = res.data.inventorySection2.package_options.split(',');
                 if(res.data.inventorySection2.technology_options){
                const checkedoptionsTechnology = res.data.inventorySection2.technology_options.split(',');
                setCheckedoptionsTechnology(checkedoptionsTechnology);
                
           }            
               const checkedoptionstax = res.data.inventorySection2.tax_type.split(',');
               setCheckedoptions(checkedoptions);
              
               setCheckedoptionstax(checkedoptionstax);
               
            //    console.log(res.data.inventorySection2.tax_type.split(','));
           }
           else if(res.data.status === 404){
        //   Swal("Error",res.data.error,"error");
             //navigate.push('/view-inventory');
             navigate('/inventorySection2/'+id);
            //  console.log(res.data.error);
        
           }
           setIsLoading(false);
        });
       }, []);



       const checkValue = (event) => {
        // setPackageOptions(event.target.value);
        // console.log(packageOptions);
      }
      
      const handleCheckOptions = (event) => {
        var updatedList = [...checkedoptions];
        if (event.target.checked) {
          updatedList = [...checkedoptions, event.target.value];
        } else {
          updatedList.splice(checkedoptions.indexOf(event.target.value), 1);
        }
        const optionsString = updatedList.join(','); // Convert the array to a comma-separated string
        setCheckedoptions(updatedList);
       // setPackage_options(updatedList);
         setInventory({...inventory, package_options : optionsString})
        //console.log(updatedList);
      }
    
      const handleCheckOptionsTechnology = (event) => {
        var updatedList = [...checkedoptionsTechnology];
        if (event.target.checked) {
          updatedList = [...checkedoptionsTechnology, event.target.value];
        } else {
          updatedList.splice(checkedoptionsTechnology.indexOf(event.target.value), 1);
        }
        const optionsString = updatedList.join(','); // Convert the array to a comma-separated string
        setCheckedoptionsTechnology(updatedList);
       // setPackage_options(updatedList);
         setInventory({...inventory, technology_options : optionsString})
       
      }

   
            const handleCheckOptionstax = (event) => {
            
                var updatedList = [...checkedoptionstax];
                if (event.target.checked) {
                  updatedList = [...checkedoptionstax, event.target.value];
                } else {
                  updatedList.splice(checkedoptionstax.indexOf(event.target.value), 1);
                }
                setCheckedoptionstax(updatedList);
                const optionsString = updatedList.join(','); 
                setInventory({...inventory, tax_type : optionsString})             
              };    
    

      async function update(event)
      {
       event.preventDefault();
          setIsLoading(true);
    //    const formData = new FormData()

    //   formData.append('inventory_section_id', inventory.inventory_section_id)
    //   formData.append('package_options', package_options)
    //   formData.append('purchased_date', inventory.purchased_date)
    //   formData.append('received_date', inventory.received_date)
    //   formData.append('delivered_date', inventory.delivered_date)
    //   formData.append('recon_completed_date', inventory.recon_completed_date)
    //   formData.append('title_received', inventory.title_received)
    //   formData.append('ownership_status', inventory.ownership_status)
    //   formData.append('purchaser_type', inventory.purchaser_type)
    //   formData.append('purchaser_name', inventory.purchaser_name)
    //   formData.append('company_name', inventory.company_name)
    //   formData.append('rin_number', inventory.rin_number)
    //   formData.append('phone_no', inventory.phone_no)
    //   formData.append('mobile_no', inventory.mobile_no)
    //   formData.append('email', inventory.email)
    //   formData.append('address', inventory.address)
    //   formData.append('apart_or_suite', inventory.apart_or_suite)
    //   formData.append('city', inventory.city)
    //   formData.append('province', inventory.province)
    //   formData.append('postal_code', inventory.postal_code)
    //   formData.append('country', 'canada')
    //   formData.append('tax_type', tax_type)
    //   formData.append('purchase_price', inventory.purchase_price)
    //   formData.append('actual_cash_value', inventory.actual_cash_value)
    //   formData.append('discount', inventory.discount)
    //   formData.append('tax', inventory.tax)

 
          const data = inventory;
           await axios.put(`${API_URL}/update-inventory2/` +id ,data).then(res =>{
//         if(res.data.status === 200){
// Swal("Success",res.data.message,"success");
//         }
//        else if(res.data.status === 422){
        
//         setErrorList(res.data.validate_err);
//         Swal("Error",res.data.message,"error");
        
//        }
//        else if(res.data.status === 404){
//         Swal("Error",res.data.message,"error");
//        }

    // Swal.fire({
    //           icon:"success",
    //           text:res.data.message
    //         }).then(function(){
                setErrorList([]);
                const inventory_section_id = res.data.inventorySection2.inventory_section_id;  
           
               navigate('/edit-inventorySection3/'+inventory_section_id );
            //   navigate('/InventorySection2'/${inventory_section_id});
            
        //   });
          }).catch(function(error) {
            setIsLoading(false);
             // if(error.response.status===422){         
             setErrorList(error.response.data.validate_err);
             // }
         //    else{
              Swal.fire({
             
                text:error.response.data.message,
                icon:"error"
              })
         //    }
          })
       
          }
      



    return(
<div>

<SideNav/>
    <TopBar/>

    {isLoading?
     <div className="loader-container" style ={{backgroundColor: "rgba(0, 0, 0, 0.5)", position: "fixed",top: "0",left: "0",width: "100%",height: "100%",display: "flex",justifyContent: "center",alignItems: "center",zIndex: "9999"}}>
    <div class="d-flex justify-content-center">
  <div class="spinner-border" role="status">
  </div>
  </div>
</div>:null}

    <div class="d-flex">

<div class="right_panel position create_inventory h-100" id="right_panel">

{/* <!-- Main Title --> */}
    <div class="main_title"  data-aos-delay="" data-aos-duration="1200">
        <h1><i class="bi bi-plus-lg h1"></i> Edit Inventory</h1>
    </div>
  

    <div class="content_wrapper pt-0">
      {/* <!-- -------------------- -->
        <!-- Options and Purchase -->
        <!-- -------------------- --> */}
        <div id="inventory_section2" >
            <div class="row">
            <div class="col">
                <div class="vm_content_box_1 h-100 select_option">
                    <div class="title">
                        <h2><i class="bi bi-check-lg"></i> Select Options</h2>
                    </div>
                    <div class="content pt-3">
                        <div class="row pb-2">
                            <div class="col-md-12 form-group">
                                <div class="d-flex input_wrapper">
                                    <div class="icon_wrapper">
                                        <i class="bi bi-search icon"></i>
                                    </div>
                                    <input class="form-control" name="brandType" id="brandType" placeholder="Brand Type" type="text"/>
                                </div>
                            </div>
                        </div>
                 
                        <h4 class="border-bottom pb-2 mb-2 vm_font_semi_bold">High Value Options</h4>
                        <div class="row py-3">
                        
                                    {checklistoptions.map((item, index) => (
                                         <div class="col-md-6 pb-2">
                                    <div key={index}>
                                        
                                         <div class="form-group-checkbox d-flex align-items-center justify-content-between">
                                         <div class="d-flex align-items-center">
                                    <input class="checkbox" value={item} checked={checkedoptions.includes(item)}
                                     type="checkbox" name="checkedoptions"  id={`package_options${index}`} onChange={handleCheckOptions} />
                                  
                                    <label for="package_options" className={isCheckedoptions(item)}>{item}</label>
                                  
                                    </div>
                                    
                                    </div>
                                    </div>
                                    
                                    </div>
                                    
                                    ))}
                              
                                <span class="text-danger">{error_list.package_options}</span>
                                   
                         
                           
                        </div>

                        <h4 class="border-bottom pb-2 mb-2 vm_font_semi_bold">Technology Options</h4>
                        <div class="row py-3">
                        
                                    {technologyoptions.map((item, index) => (
                                         <div class="col-md-6 pb-2">
                                    <div key={index}>
                                        
                                         <div class="form-group-checkbox d-flex align-items-center justify-content-between">
                                         <div class="d-flex align-items-center">
                                    <input class="checkbox" value={item} checked={checkedoptionsTechnology.includes(item)}
                                     type="checkbox" name="technology_options" id={`technology_options${index}`}  onChange={handleCheckOptionsTechnology} />
                                  
                                    <label for="technology_options" className={isCheckedoptionsTechnology(item)}>{item}</label>
                                  
                                    </div>
                                    
                                    </div>
                                    </div>
                                    
                                    </div>
                                    
                                    ))}
                              
                                <span class="text-danger">{error_list.technology_options}</span>
                                   
                         
                           
                        </div>

                        <h4 class="border-bottom pb-2 mb-2 vm_font_semi_bold">Technology Options</h4>
                        <div class="row py-3">
                            <div class="col-md-6 pb-2">
                                <div class="form-group-checkbox d-flex align-items-center justify-content-between">
                                    <div class="d-flex align-items-center">
                                        <input class="checkbox" type="checkbox" name="AdaptiveCruseControl" id="AdaptiveCruseControl" onChange={checkValue}/>
                                        <label for="AdaptiveCruseControl">Adaptive Cruse Control</label>
                                    </div>
                                    <i class="bi bi-star-fill star" id="9" onclick="favouriteStar(this.id)"></i>
                                </div>
                            </div>
                            <div class="col-md-6 pb-2">
                                <div class="form-group-checkbox d-flex align-items-center justify-content-between">
                                    <div class="d-flex align-items-center">
                                        <input class="checkbox" type="checkbox" name="AndroidAudio" id="AndroidAudio" onChange={checkValue}/>
                                        <label for="AndroidAudio">Android Audio</label>
                                    </div>
                                    <i class="bi bi-star-fill star" id="10" onclick="favouriteStar(this.id)"></i>
                                </div>
                            </div>
                            <div class="col-md-6 pb-2">
                                <div class="form-group-checkbox d-flex align-items-center justify-content-between">
                                    <div class="d-flex align-items-center">
                                        <input class="checkbox" type="checkbox" name="AuxIn" id="AuxIn" onChange={checkValue}/>
                                        <label for="AuxIn">Aux In</label>
                                    </div>
                                    <i class="bi bi-star-fill star" id="11" onclick="favouriteStar(this.id)"></i>
                                </div>
                            </div>
                            <div class="col-md-6 pb-2">
                                <div class="form-group-checkbox d-flex align-items-center justify-content-between">
                                    <div class="d-flex align-items-center">
                                        <input class="checkbox" type="checkbox" name="AutomaticLight" id="AutomaticLight" onChange={checkValue}/>
                                        <label for="AutomaticLight">Automatic Light</label>
                                    </div>
                                    <i class="bi bi-star-fill star" id="12" onclick="favouriteStar(this.id)"></i>
                                </div>
                            </div>
                            <div class="col-md-6 pb-2">
                                <div class="form-group-checkbox d-flex align-items-center justify-content-between">
                                    <div class="d-flex align-items-center">
                                        <input class="checkbox" type="checkbox" name="LuxaryPackage2" id="LuxaryPackage2" onChange={checkValue}/>
                                        <label for="LuxaryPackage2">Luxary Package</label>
                                    </div>
                                    <i class="bi bi-star-fill star" id="13" onclick="favouriteStar(this.id)"></i>
                                </div>
                            </div>
                            <div class="col-md-6 pb-2">
                                <div class="form-group-checkbox d-flex align-items-center justify-content-between">
                                    <div class="d-flex align-items-center">
                                        <input class="checkbox" type="checkbox" name="AppleCarPlay" id="AppleCarPlay" onChange={checkValue}/>
                                        <label for="AppleCarPlay">Apple Car Play</label>
                                    </div>
                                    <i class="bi bi-star-fill star" id="14" onclick="favouriteStar(this.id)"></i>
                                </div>
                            </div>
                            <div class="col-md-6 pb-2">
                                <div class="form-group-checkbox d-flex align-items-center justify-content-between">
                                    <div class="d-flex align-items-center">
                                        <input class="checkbox" type="checkbox" name="BlindSpotMonitor" id="BlindSpotMonitor" onChange={checkValue}/>
                                        <label for="BlindSpotMonitor">Blind Spot Monitor</label>
                                    </div>
                                    <i class="bi bi-star-fill star" id="15" onclick="favouriteStar(this.id)"></i>
                                </div>
                            </div>
                            <div class="col-md-6 pb-2">
                                <div class="form-group-checkbox d-flex align-items-center justify-content-between">
                                    <div class="d-flex align-items-center">
                                        <input class="checkbox" type="checkbox" name="BirdsEyeViewCamera" id="BirdsEyeViewCamera" onChange={checkValue}/>
                                        <label for="BirdsEyeViewCamera">Birds Eye View Camera</label>
                                    </div>
                                    <i class="bi bi-star-fill star" id="16" onclick="favouriteStar(this.id)"></i>
                                </div>
                            </div>
                        </div>
                    {/* </div> */}
                </div>
                
                </div>
                </div>
            <div class="col ms-lg-4 pt-4 pt-lg-0">
                <div class="vm_content_box_1 h-100">
                    <div class="title">
                        <h2><i class="bi bi-currency-dollar"></i> Purchase</h2>
                    </div>
                    <div class="content pt-3">
                        <div class="row pb-2">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="Purchasedon_date">Purchased on</label>
                                    <div class="d-flex input_wrapper">
                                        <div class="icon_wrapper">

                                        </div>
                                        <input type="date" class="form-control" name="purchased_date" id="purchased_date" placeholder="Select Date" 
                                         value={inventory.purchased_date}  onChange={handleInput } onblur="(this.type='text')" onfocus="(this.type='date')" required/>
                                    
                                    </div>
                                    <span class="text-danger">{error_list.purchased_date}</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="received_date">Date Received</label>
                                    <div class="d-flex input_wrapper">
                                        <div class="icon_wrapper">
                                           
                                        </div>
                                        <input type="date" class="form-control" name="received_date" id="received_date" placeholder="Select Date" onblur="(this.type='text')" onfocus="(this.type='date')" 
                                        value={inventory.received_date}  onChange={handleInput }/>
                                       
                                        
                                    </div>
                                    <span class="text-danger">{error_list.received_date}</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="delivered_date">Date Delivered</label>
                                    <div class="d-flex input_wrapper">
                                        <div class="icon_wrapper">
                                           
                                        </div>
                                        <input type="date" class="form-control" name="delivered_date" id="delivered_date" placeholder="Select Date" onblur="(this.type='text')" onfocus="(this.type='date')"
                                        value={inventory.delivered_date}  onChange={handleInput }/>
                                       
                                    </div>
                                    <span class="text-danger">{error_list.delivered_date}</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="recon_ompleted">Recon Completed</label>
                                    <div class="d-flex input_wrapper">
                                        <div class="icon_wrapper">
                                          
                                        </div>
                                        <input type="date" class="form-control" name="recon_completed_date" id="recon_completed_date" placeholder="Select Date" onblur="(this.type='text')" onfocus="(this.type='date')"
                                        value={inventory.recon_completed_date}  onChange={handleInput }/>
                                       
                                    </div>
                                    <span class="text-danger">{error_list.recon_completed_date}</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="title_received">Title Received</label>
                                    <div class="d-flex input_wrapper">
                                        <div class="icon_wrapper">
                                           
                                        </div>
                                        <input type="date" class="form-control" name="title_received" id="title_received" placeholder="Select Date" onblur="(this.type='text')" onfocus="(this.type='date')"
                                        value={inventory.title_received}  onChange={handleInput }/>
                                        
                                    </div>
                                    <span class="text-danger">{error_list.title_received}</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="condition">Ownership Status</label>
                                    <select class="form-select" name="ownership_status" id="ownership_status" value={inventory.ownership_status} onChange={handleInput }>
                                        <option value="">Select Status</option>
                                        <option value="Status 1">Status 1</option>
                                        <option value="Status 2">Status 2</option>
                                    </select>
                                 
                                </div>
                                <span class="text-danger">{error_list.ownership_status}</span>
                            </div>
                        </div>
                        <h4 class="border-bottom pb-2 mb-3 vm_font_semi_bold">Purchased From</h4>
                        <div class="row">
                            <div class="col-md-12 form-group">
                                <div class="d-flex input_wrapper">
                                    <div class="icon_wrapper">
                                        <i class="bi bi-search icon"></i>
                                    </div>
                                    <input class="form-control" name="vendor_search" id="vendor_search" placeholder="Vendor Search" type="text"
                                    />
                                </div>
                            </div>
                        </div>
                        <p>Didn't find what you're looking for? <a href="#" class="text-primary">Add New</a></p>
                        <div class="form-group pt-3 pb-2 d-md-flex">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="radio" role="switch" name="purchaser_type" id="purchaser_type" value="Public" checked={inventory.purchaser_type=='Public'}
                                onChange={handleInput}/>
                                <label class="form-check-label" for="purchase_public">Public</label>
                            </div>
                            <div class="form-check form-switch ms-md-3 pt-3 pt-md-0">
                                <input class="form-check-input" type="radio" role="switch" name="purchaser_type" id="purchaser_type" value="Company" checked={inventory.purchaser_type=='Company'}
                                 onChange={handleInput}/>
                                <label class="form-check-label" for="purchase_company">Company</label>
                            </div>
                            <span class="text-danger" style={{margin:"0px 20px"}}>{error_list.purchaser_type}</span>
                        </div>
                       
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="name">Name</label>
                                    <input type="text" class="form-control" name="purchaser_name" id="purchaser_name" value={inventory.purchaser_name} placeholder="Enter Name"
                                    onChange={handleInput}/>
                                      <span class="text-danger">{error_list.purchaser_name}</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="company">Company</label>
                                    <input type="text" class="form-control" name="company_name" id="company_name" value={inventory.company_name} placeholder="Enter Company"
                                     onChange={handleInput}/>
                                      <span class="text-danger">{error_list.company_name}</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="rin">RIN</label>
                                    <input type="text" class="form-control" name="rin_number" id="rin_number" value={inventory.rin_number} placeholder="Enter RIN Number"
                                     onChange={handleInput}/>
                                      <span class="text-danger">{error_list.rin_number}</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="phone">Phone</label>
                                    <input type="text" class="form-control" name="phone_no" id="phone_no" value={inventory.phone_no} placeholder="Enter Phone Number"
                                    onChange={handleInput}/>
                                      <span class="text-danger">{error_list.phone_no}</span>
                                </div>
                            </div>
                            {/* <div class="col-md-6">
                                <div class="form-group">
                                    <label for="mobile">Mobile</label>
                                    <input type="text" class="form-control" name="mobile_no" id="mobile_no" value={inventory.mobile_no}  placeholder="Enter Mobile Number"
                                     onChange={handleInput}/>
                                      <span class="text-danger">{error_list.mobile_no}</span>
                                </div>
                            </div> */}
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="email">Email</label>
                                    <input type="text" class="form-control" name="email" id="email" value={inventory.email} placeholder="Enter Email Address"
                                    onChange={handleInput}/>
                                     <span class="text-danger">{error_list.email}</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="address">Enter Location</label>
                                    <input type="text" class="form-control" name="address" id="address" value={inventory.address} placeholder="Enter Location"
                                    onChange={handleInput}/>
                                     <span class="text-danger">{error_list.address}</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="apt_suite">Apt/Suite #</label>
                                    <input type="text" class="form-control" name="apart_or_suite" id="apart_or_suite" value={inventory.apart_or_suite} placeholder="Enter Apt/Sute #"
                                    onChange={handleInput}/>
                                     <span class="text-danger">{error_list.apart_or_suite}</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="city">City</label>
                                    <input type="text" class="form-control" name="city" id="city" value={inventory.city} placeholder="Enter City"
                                    onChange={handleInput}/>
                                    <span class="text-danger">{error_list.city}</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="condition">Select State</label>
                                    <select class="form-select" name="province" id="province" value={inventory.province} onChange={handleInput}>
                                    <option value="">Select State</option>
                                        <option value="Alberta">Alberta</option>
                                        <option value="British Columbia">British Columbia</option>
                                        <option value="Manitoba">Manitoba</option>
                                        <option value="New Brunswick">New Brunswick</option>
                                        <option value="Newfoundland and Labrador">Newfoundland and Labrador</option>
                                        <option value="Nova Scotia">Nova Scotia</option>
                                        <option value="Ontario">Ontario</option>
                                        <option value="Prince Edward Island">Prince Edward Island</option>
                                        <option value="Quebec">Quebec</option>
                                        <option value="Saskatchewan">Saskatchewan</option>
                                        <option value="Northwest Territories">Northwest Territories</option>
                                        <option value="Nunavut">Nunavut</option>
                                        <option value="Yukon">Yukon</option>
                                    </select>
                                    <span class="text-danger">{error_list.province}</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="postal_code">Postal Code </label>
                                    <input type="text" class="form-control" name="postal_code" id="postal_code" value={inventory.postal_code} placeholder="Enter Postal Code"
                                    onChange={handleInput}/>
                                    <span class="text-danger">{error_list.postal_code}</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="" >Country</label>
                                    <select class="form-select" name="country" id="country" value={inventory.country}>
                                        <option value="CA">CA</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="highlightbox_1">
                            <div class="highlightbox_1_title">Tax Details</div>
                            <div class="highlightbox_1_wrapper justify-content-start">
                                


                                {checklistoptionstax.map((item, index) => (
                                      <div class="form-group pt-3 pb-2 d-md-flex form-check form-switch ps-5">
                                    <div key={index}>
                                        
                                         
                                    <input  class="form-check-input"  name="tax_type" id="tax_type" 
                                    value={item} type="checkbox"  checked={checkedoptionstax.includes(item)}
                                    onChange={handleCheckOptionstax} />
                                    <label for="form-check-label ms-2" className={isCheckedoptionstax(item)}>{item}</label>
                                   
                                    </div>
                        
                                    </div>
                                    ))}


{/* <div class="form-group pt-3 pb-2 d-md-flex form-check form-switch ps-5">
    <div>
                                   <input  class="form-check-input"  name="tax_type" id="tax_type" 
                                    value="Tax Exempt" type="checkbox"  checked={checkedoptionstax.includes('Tax Exempt')}
                                    onChange={handleCheckboxChange} />
                                     <label for="form-check-label ms-2" >Tax Exempt</label>
                                     </div>
                                     </div>  
                                     <div class="form-group pt-3 pb-2 d-md-flex form-check form-switch ps-5"> 
                                     <div>         
                                          <input  class="form-check-input"  name="tax_type" id="tax_type" 
                                    value="Tax Override" type="checkbox"  checked={checkedoptionstax.includes('Tax Override')}
                                    onChange={handleCheckboxChange} />
                                    <label for="form-check-label ms-2" >Tax Override</label>
                                    </div>
</div> */}


                                     <span class="text-danger" style={{margin: "15px"}}>{error_list.tax_type}</span>
                              
                               
                            </div>
                            <div class="highlightbox_1_wrapper">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="purchase_price">Purchase Price</label>
                                        <input type="text" class="form-control" name="purchase_price" value={inventory.purchase_price} id="PurchasePrice" placeholder="0.00"
                                        onChange={handleInput}/>
                                         <span class="text-danger">{error_list.purchase_price}</span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="actual_cash_value">Actual Cash Value</label>
                                        <input type="text" class="form-control" name="actual_cash_value" value={inventory.actual_cash_value} id="ActualCashValue" placeholder="0.00"
                                        onChange={handleInput}/>
                                         <span class="text-danger">{error_list.actual_cash_value}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="highlightbox_1_wrapper">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="Discount">Discount</label>
                                        <input type="text" class="form-control" name="discount" value={inventory.discount} id="discount" placeholder="0.00"
                                        onChange={handleInput}/>
                                          <span class="text-danger">{error_list.discount}</span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="Tax">Tax</label>
                                        <input type="text" class="form-control" name="tax" id="tax" value={inventory.tax} placeholder="0.00"
                                        onChange={handleInput}/>
                                          <span class="text-danger">{error_list.tax}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>

            {/* <!-- Next Button --> */}

            {/* {inventory.map(function fn(item){  
                                    return( */}
            <div class="d-flex justify-content-md-start position-fixed bottom-0 vm_zindex_99 w-100 fixed_inv_btn">
                <div class="bg-dark px-4 start-0 end-0 pt-2 pb-2 vm_font_semi_bold text-white text-uppercase vm_cursor_pointer" >
                <Link to ={`/edit-inventory/${id}`} style={{color:"white"}}>
                    <i class="bi bi-arrow-left-short"></i> Back</Link>
                </div>
     
                <div class="vm_bg_secondary px-4 start-0 end-0 pt-2 pb-2 vm_font_semi_bold text-white text-uppercase vm_cursor_pointer" 
                onClick={update}>Next <i class="bi bi-arrow-right-short"></i>
                </div> 
               
              
            </div>
            {/* );
        })} */}
        
        </div> 






    </div>
</div>
</div>
    
</div>
    );

}
export default EditInventorySection2;