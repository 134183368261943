import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {

  const API_URL = process.env.REACT_APP_API_URL;
  const [username, setUsername] = useState(() => {
    return localStorage.getItem('username') || ""; // Retrieve from local storage
});
  const [userDetails, setUserDetails] = useState([]);

  useEffect(() => {
    const storedUsername = localStorage.getItem('username');
    if (storedUsername) {
      setUsername(storedUsername);
      fetchUserDetails(storedUsername);
    }
  }, []);

  useEffect(() => {
    if (username) {
      fetchUserDetails(username);
    }
  }, [username]);

  const fetchUserDetails = async (username) => {
    try {
      const res = await axios.get(`${API_URL}/view-team-member-by-username/${username}`);
      setUserDetails(res.data.team);
      //console.log(res.data.team) ;// Assuming `team` contains user details including role
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };
  

  return (
    <UserContext.Provider value={{ username, setUsername, userDetails, setUserDetails }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext