import React, {useState,useEffect} from "react";
import './App.css';
import $ from 'jquery';
import ViewInventory from './components/ViewInventory';
import SideNav from './components/SideNav';
import TopBar from "./components/TopBar";
import TopBarInventory from "./components/TopBarInventory";
import {BrowserRouter as Router,Routes, Switch, Route, useNavigate} from 'react-router-dom'
import Inventory from './components/Inventory';
import EditInventory from './components/EditInventory';
import InventorySection2 from "./components/InventorySection2";
import InventorySection3 from "./components/InventorySection3";
import EditInventorySection2 from "./components/EditInventorySection2";
import EditInventorySection3 from "./components/EditInventorySection3";
import SettingInvoicePreset from "./components/SettingInvoicePreset";
import EditSettingInvoicePreset from "./components/EditSettingInvoicePreset";
import SettingImagePreset from "./components/SettingImagePreset";
import Login from "./components/Login";
import SettingImageEditing from "./components/SettingImageEditing";
import InventoryDetails from "./components/InventoryDetails";
import { UserProvider } from './components/UserContext';
import VehicleList from "./components/VehicleList";
import EmailLogs from "./components/EmailLogs";
import ViewCustomers from "./components/ViewCustomers";
import CreateCustomer from "./components/CreateCustomer";
import EditCustomer from "./components/EditCustomer";
import ViewLeads from "./components/ViewLeads";
import LeadsDetails from "./components/LeadsDetails";
import SettingEmailTemplate from "./components/SettingEmailTemplate";
import EditSettingEmailTemplate from "./components/EditSettingEmailTemplate";
import SettingSmsTemplate from "./components/SettingSmsTemplate";
import EditSettingSmsTemplate from "./components/EditSettingSmsTemplate";
import SettingNoteTemplate from "./components/SettingNoteTemplate";
import EditSettingNoteTemplate from "./components/EditSettingNoteTemplate";
import SettingPinTemplate from "./components/SettingPinTemplate";
import EditSettingPinTemplate from "./components/EditSettingPinTemplate";
import SettingMeetingTemplate from "./components/SettingMeetingTemplate";
import ViewDashboard from "./components/ViewDashboard";
import SettingUploadTemplate from "./components/SettingUploadTemplate";
import EditSettingUploadTemplate from "./components/EditSettingUploadTemplate";
import ViewTeam from "./components/ViewTeam";
import CreateTeamMember from "./components/CreateTeamMember";
import EditTeamMember from "./components/EditTeamMember";
import Statistics from "./components/Statistics";
import ProtectedRoute from './components/ProtectedRoute';

function App() {


  useEffect(() => {
   
  }, []);
  
  return (
    <UserProvider>
    <Router >
    <div className="App">
    
      <Routes>


   <Route path='*' element={<Login />} /> 
   <Route path='/top-bar' element={<TopBar />} />
   <Route path='/top-bar-inventory' element={<TopBarInventory />} />

   <Route path='/view-dashboard' element={<ProtectedRoute><ViewDashboard /></ProtectedRoute>} />

   <Route path='/view-inventory' element={<ProtectedRoute><ViewInventory /></ProtectedRoute>} />
   <Route path='/inventory/:id' element={<ProtectedRoute><Inventory /></ProtectedRoute>}/>
    <Route path='/inventory' element={<ProtectedRoute><Inventory /></ProtectedRoute>}/>
    <Route path='/edit-inventory/:id' element={<ProtectedRoute><EditInventory /></ProtectedRoute>} />

       <Route path='/inventorySection2/:inventory_section_id' element={<ProtectedRoute><InventorySection2 /></ProtectedRoute>} />
    <Route path='/edit-inventorySection2/:id' element={<ProtectedRoute><EditInventorySection2 /></ProtectedRoute>} />

    <Route path='/inventorySection3/:inventory_section_id' element={<ProtectedRoute><InventorySection3 /></ProtectedRoute>} />
    <Route path='/edit-inventorySection3/:inventory_section_id' element={<ProtectedRoute><EditInventorySection3 /></ProtectedRoute>} />

    <Route path='/setting-invoice-preset' element={<ProtectedRoute><SettingInvoicePreset /></ProtectedRoute>} />
    <Route path='/edit-invoice-preset/:id' element={<ProtectedRoute><EditSettingInvoicePreset /></ProtectedRoute>} />

    <Route path='/setting-image-preset' element={<ProtectedRoute><SettingImagePreset /></ProtectedRoute>} />

    <Route path='/setting-image-editing' element={<ProtectedRoute><SettingImageEditing /></ProtectedRoute>} />

    <Route path='/setting-email-template' element={<ProtectedRoute><SettingEmailTemplate /></ProtectedRoute>} />
    <Route path='/edit-email-template/:id' element={<ProtectedRoute><EditSettingEmailTemplate /></ProtectedRoute>} />

    <Route path='/setting-sms-template' element={<ProtectedRoute><SettingSmsTemplate /></ProtectedRoute>} />
    <Route path='/edit-sms-template/:id' element={<ProtectedRoute><EditSettingSmsTemplate /></ProtectedRoute>} />

    <Route path='/setting-note-template' element={<ProtectedRoute><SettingNoteTemplate /></ProtectedRoute>}  />
    <Route path='/edit-note-template/:id' element={<ProtectedRoute><EditSettingNoteTemplate /></ProtectedRoute>} />

    <Route path='/setting-pin-template' element={<ProtectedRoute><SettingPinTemplate /></ProtectedRoute>} />
    <Route path='/edit-pin-template/:id' element={<ProtectedRoute><EditSettingPinTemplate /></ProtectedRoute>} />

    <Route path='/setting-meeting-template' element={<ProtectedRoute><SettingMeetingTemplate /></ProtectedRoute>} />

    <Route path='/setting-upload-template' element={<ProtectedRoute><SettingUploadTemplate /></ProtectedRoute>} />
    <Route path='/edit-upload-template/:id' element={<ProtectedRoute><EditSettingUploadTemplate /></ProtectedRoute>} />

    <Route path='/inventory-details/:id' element={<ProtectedRoute><InventoryDetails /></ProtectedRoute>} />
    <Route path='/vehicle-list' element={<ProtectedRoute><VehicleList /></ProtectedRoute>} />
    <Route path='/email-logs' element={<ProtectedRoute><EmailLogs /></ProtectedRoute>} />

    <Route path='/view-customers' element={<ProtectedRoute><ViewCustomers /></ProtectedRoute>} />
    <Route path='/create-customer' element={<ProtectedRoute><CreateCustomer /></ProtectedRoute>} />
    <Route path='/edit-customer/:id' element={<ProtectedRoute><EditCustomer /></ProtectedRoute>} />

    <Route path='/view-leads' element={<ProtectedRoute><ViewLeads /></ProtectedRoute>} />
    <Route path='/leads-details/:id' element={<ProtectedRoute><LeadsDetails /></ProtectedRoute>} />
    <Route path='/leads-details' element={<ProtectedRoute><LeadsDetails /></ProtectedRoute>} />

    <Route path='/view-team' element={<ProtectedRoute><ViewTeam /></ProtectedRoute>} />
    <Route path='/create-team-member' element={<ProtectedRoute><CreateTeamMember /></ProtectedRoute>} />
    <Route path='/edit-team-member/:id' element={<ProtectedRoute><EditTeamMember /></ProtectedRoute>} />

    <Route path='/statistics' element={<ProtectedRoute><Statistics /></ProtectedRoute>} />
    
  </Routes>
 

    </div>
    </Router>
    </UserProvider>
  );
}

export default App;
