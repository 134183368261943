import { React, useState,useContext, useEffect } from "react";
import Swal from 'sweetalert2';
import axios from 'axios';
import {Link, useParams, useNavigate} from "react-router-dom";
import UserContext from './UserContext';
import $ from 'jquery';
import NoImageUser from '../img/user_avatar.webp';
import ImageUser from '../img/user_login_photo.webp';

// function TopBar({ handleSearch, totalCount , selectedCount, filteredResults }){

  function TopBar(){

    const LARAVEL_API_URL = process.env.REACT_APP_LARAVEL_API;
    const API_URL = process.env.REACT_APP_API_URL;
    const [login, setLogin] = useState([]);
    //const { username } = useParams();
    const [message, setMessage] = useState('');
    const [username, setUsername] = useState('');
    const userContext = useContext(UserContext);  
    const [searchText, setSearchText] = useState('');
    const navigate = useNavigate();
    const [userDetails, setUserDetails] = useState([]);
    // const handleChange = (e) => {
    //   setSearchText(e.target.value);
    // };
  
    // const handleSubmit = (e) => {
    //   e.preventDefault();
    //   handleSearch(searchText);
    //   console.log(searchText);
    // };
  

    useEffect(() => {
      
      const storedUsername = localStorage.getItem('username');
      if (storedUsername) {
        setUsername(storedUsername);
        // fetchUserDetails(storedUsername);
      } else if (userContext.username) {
        localStorage.setItem('username', userContext.username);
        setUsername(userContext.username);
        // fetchUserDetails(userContext.username);
      }
    
    }, [userContext.username]);

   
    const fetchUserDetails = async (username) => {
      try {
        const res = await axios.get(`${API_URL}/view-team-member-by-username/${username}`);
        setUserDetails(res.data.team);
        console.log(res.data.team) ;// Assuming `team` contains user details including role
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };
    
    const handleLogout = () => {
      localStorage.removeItem('username');
      userContext.setUsername('');
      navigate('/login');
    };

    function hideMenu() {
      document.getElementById("left_panel").style.left = "-127px";
      document.getElementById("left_panel").style.transition = "all 0.5s ease";
      document.getElementById("left_panel").classList.add("menuHoverClass");
      document.getElementById("topbar").style.paddingLeft = "57px";
      document.getElementById("right_panel").style.paddingLeft = "46px";
      document.getElementById("hideMenu").style.display = "none";
      document.getElementById("showMenu").style.display = "block";
      document.getElementById("mainlogo_big").style.display = "none";
      document.getElementById("mainlogo_small").style.display = "flex";
      $(".cd-accordion__input").prop("checked", false);
    }
    function showMenu() {
      document.getElementById("left_panel").style.left = "0";
      document.getElementById("left_panel").classList.remove("menuHoverClass");
      document.getElementById("topbar").style.paddingLeft = "184px";
      document.getElementById("right_panel").style.paddingLeft = "172px";
      document.getElementById("hideMenu").style.display = "block";
      document.getElementById("showMenu").style.display = "none";
      document.getElementById("mainlogo_big").style.display = "flex";
      document.getElementById("mainlogo_small").style.display = "none";
    }
    return(
      
            
            <div class="border-bottom pe-3 d-flex align-items-center justify-content-between bg-white topbar w-100 position-fixed" id="topbar">
    <div class="d-flex align-items-center">
    <i class="bi bi-arrow-left-short vm_font_bold vm_cursor_pointer fs-3" id="hideMenu" onClick={hideMenu}></i>
        <i class="bi bi-list vm_font_bold vm_cursor_pointer fs-3" id="showMenu" onClick={showMenu} style={{display: "none"}}></i>
        {/* <div class="d-flex align-items-center ms-3">
        <button class="border-0 bg-transparent"><i class="bi bi-search"></i></button>
            <input class="form-control search_input rounded-0 border-0 bg-transparent" placeholder="Search in Front" type="text"/> */}

        {/* <form onSubmit={handleSubmit} className="row align-items-center"> */}
  {/* <div className="col-auto">
    <button className="btn border-0 bg-transparent">
      <i className="bi bi-search"></i>
    </button>
  </div>
  <div className="col">
    <input
      className="form-control search_input rounded-0 border-0 bg-transparent"
      placeholder="Search in Front"
      type="text"
      // value={searchText}
      // onChange={handleChange}
    />
  </div> */}
  {/* <div className="col-auto"> */}
    {/* <span>{`${selectedCount}/${totalCount}`}</span> */}
  {/* </div> */}
{/* </form> */}

         {/* <button class="border-0 bg-transparent"><i class="bi bi-search"></i></button>
            <input class="form-control search_input rounded-0 border-0 bg-transparent" 
         placeholder="Search in Front" type="text" value={searchText} onChange={handleChange}/> */}
        {/* </div> */}
    </div>
    <div class="d-flex align-items-center">
        <i class="bi bi-bell fs-5"></i>
        <div class="user d-flex align-items-center ps-md-3">
            <p class="d-md-block d-none">{userContext.username}</p>
            {/* <p class="d-md-block d-none">{userDetails.user_role}</p> */}
            <div class="dropdown">
                {/* <div class="user_photo ms-2 vm_cursor_pointer vm_bg_center vm_bg_cover vm_bg_norepeat"  style={{background: `url(${LARAVEL_API_URL}/${userDetails.user_photo_path})`}}  data-bs-toggle="dropdown" aria-expanded="false"></div> */}
                <div class="user_photo ms-2 vm_cursor_pointer vm_bg_center vm_bg_cover vm_bg_norepeat"   style={{
    background: `url(${ImageUser})`,
  }}    data-bs-toggle="dropdown" aria-expanded="false"></div>
                <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="#"><i class="bi bi-person"></i> Profile</a></li>
                    <li><a class="dropdown-item" href="#"><i class="bi bi-gear"></i> Setting</a></li>
                    <li><a class="dropdown-item" onClick={handleLogout} ><i class="bi bi-box-arrow-right"></i> Logout</a></li>
                </ul>
            </div>
        </div>
    </div>
</div>

       
    )
}
 export default TopBar;