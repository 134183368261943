
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import SideNav from "./SideNav";
import TopBar from "./TopBar";
import {Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import $ from 'jquery';
// import AOS from 'aos';
// import 'aos/dist/aos.css';
import { Chart } from 'react-chartjs-2';


function ViewDashboard(){

    const [isLoading, setIsLoading] = useState(false);
    const API_URL = process.env.REACT_APP_API_URL;
    const [inquiry, setInquiry] = useState([]);
    const [customer, setCustomer] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [localInventory, setLocalInventory] = useState([]);
    const chartRef = useRef(null);
    const barChartInstanceRef = useRef(null);
    const barChartInstanceRef2 = useRef(null);
    const chartInstanceRef = useRef(null);
    const chartInstanceRef2 = useRef(null);


    const filteredResult = customer.filter((item) => {
        return item.first_name.toLowerCase().includes(searchQuery.toLowerCase()) 
        // || item.job_title.toLowerCase().includes(searchQuery.toLowerCase()) 
        
        
      });

      const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
      };

     

    //   useEffect(() => {
    //     if (chartRef && chartRef.current) {
    //       const chartInstance = chartRef.current.chartInstance;
    //       if (chartInstance) {
    //         chartInstance.destroy();
    //       }
    //     }
    //   }, []); 
     
    useEffect(() => {   
         setIsLoading(true);

         const $sticky = $(".sticky");
         const $stickyrStopper = $(".sticky-stopper");
         if (!!$sticky.offset()) {
           // Sticky element exists
           // Add your sticky logic here
           // Make sure to replace '$' with 'window.$' if using jQuery in a separate file
         }

         const counters = document.querySelectorAll(".value");
         const speed = 400;
     
         counters.forEach((counter) => {
           const animate = () => {
             const value = +counter.getAttribute("akhi");
             const data = +counter.innerText;
     
             const time = value / speed;
             if (data < value) {
               counter.innerText = Math.ceil(data + time);
               setTimeout(animate, 1);
             } else {
               counter.innerText = value;
             }
           };
     
           animate();
         });



        //    AOS.init({ duration: 1200 });
           getInquiry();
           getInventoryList();
           getCustomerList();

        }, []);


        useEffect(() => {

            if (barChartInstanceRef.current) {
                barChartInstanceRef.current.destroy();
              }
           
              // Create a new chart instance
              const bar = document.getElementById('dashboardBarChart1').getContext('2d');
              barChartInstanceRef.current = new Chart(bar, {
                type: 'bar',
                data: {
                    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
                  datasets: [{
                    label: "Sales Value",
                    data: [12, 19, 3, 5, 2, 3],
                    backgroundColor: ["green", "green", "green", "green", "green", "green"],
                    borderWidth: 1,
                },
                {
                    label: "Inventory",
                    data: [10, 15, 10, 8, 12, 17],
                    borderWidth: 1,
                },
            ],
        },
        options: {
            scales: {
                y: {
                    beginAtZero: true,
                },
            },
        },
                
              }); 




              if (barChartInstanceRef2.current) {
                barChartInstanceRef2.current.destroy();
              }
           
              // Create a new chart instance
              const bar2 = document.getElementById('dashboardBarChart2').getContext('2d');
              barChartInstanceRef2.current = new Chart(bar2, {
                type: 'bar',
                data: {
                    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
                  datasets: [{
                    label: "Sales Value",
                    data: [12, 19, 3, 5, 2, 3],
                    backgroundColor: ["green", "green", "green", "green", "green", "green"],
                    borderWidth: 1,
                },
                {
                    label: "Inventory",
                    data: [10, 15, 10, 8, 12, 17],
                    borderWidth: 1,
                },
            ],
        },
        options: {
            scales: {
                y: {
                    beginAtZero: true,
                },
            },
        },
                
              }); 



            if (chartInstanceRef.current) {
             chartInstanceRef.current.destroy();
           }
        
           // Create a new chart instance
           const ctx = document.getElementById('dashboardPieChart1').getContext('2d');
           chartInstanceRef.current = new Chart(ctx, {
             type: 'pie',
             data: {
                labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
               datasets: [{
                label: "Sales Value",
                data: [12, 19, 3, 5, 2, 3],
                backgroundColor: [
                    "#F6DFB2",
                    "#9fd79a",
                    "#E4F59D",
                    "#B2E9F6",
                    "#DCB2F6",
                    "#F6B2E7",
                ],
                borderWidth: 2,
            }, ],
        },
           });

           if (chartInstanceRef2.current) {
            chartInstanceRef2.current.destroy();
          }
       
          // Create a new chart instance
          const ctx2 = document.getElementById('dashboardPieChart2').getContext('2d');
          chartInstanceRef2.current = new Chart(ctx2, {
            type: 'doughnut',
            data: {
                labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
              datasets: [{
                label: "Sales Value",
                data: [50, 20, 23, 32, 5, 9],
                backgroundColor: [
                    "#F6DFB2",
                    "#9fd79a",
                    "#E4F59D",
                    "#B2E9F6",
                    "#DCB2F6",
                    "#F6B2E7",
                ],
                borderWidth: 2,
            }, ],
        },
          });

              // Clean up the chart instance on unmount
   return () => {

    if (barChartInstanceRef.current) {
        barChartInstanceRef.current.destroy();
        barChartInstanceRef.current = null;
      }
  
      if (barChartInstanceRef2.current) {
        barChartInstanceRef2.current.destroy();
        barChartInstanceRef2.current = null;
      }

    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
      chartInstanceRef.current = null;
    }

    if (chartInstanceRef2.current) {
        chartInstanceRef2.current.destroy();
        chartInstanceRef2.current = null;
      }
  };
   
 }, []);
        
         
        

        function getInquiry(){
              axios.get(`${API_URL}/view-inqueries`).then(res=>{
                if(res.data.status === 200){
                 setInquiry(res.data.inquiry);
                }
                else if(res.data.status === 404){
                    //setMessage(res.data.message);
                    }
                }); 
            }

            function formatDate(dateString) {
                const date = new Date(dateString);
                const options = { month: 'short', day: 'numeric', year: 'numeric' };
                return date.toLocaleDateString('en-US', options);
            }

            function getCustomerList(){
 
                axios.get(`${API_URL}/view-customer`).then(res=>{
                    if(res.data.status === 200){
                      const customer = res.data.customer;
                        setCustomer(res.data.customer);
                    }
                    else if(res.data.status === 404){
                        //setMessage(res.data.message);
                        console.log(res.data.status);
                        }
                        
                    setIsLoading(false);
                 });
                }

                function getInventoryList(){
 
                    axios.get(`${API_URL}/view-inventory`).then(res=>{
                        if(res.data.status === 200){
                          const localInventory = res.data.localInventory;
                          setLocalInventory(res.data.localInventory);
            

                        }
                        else if(res.data.status === 404){
                           // setMessage(res.data.message);
                            //console.log(res.data.status);
                            }
                         
                     });
                       
                    }
                   
    return(
        <div class=" d-flex dashboard">
           
            <SideNav />
            <TopBar />
            {isLoading?
     <div className="loader-container" style ={{backgroundColor: "rgba(0, 0, 0, 0.5)", position: "fixed",top: "0",left: "0",width: "100%",height: "100%",display: "flex",justifyContent: "center",alignItems: "center",zIndex: "9999"}}>
    <div class="d-flex justify-content-center">
  <div class="spinner-border" role="status">
  </div>
  </div>
</div>:null} 


<div class="right_panel position contact_section h-100" id="right_panel">
      
        <div class="main_title" data-aos="fade-down" data-aos-delay="" data-aos-duration="1200">
            <h1><i class="bi bi-graph-up-arrow"></i> Dashboard</h1>
        </div>

        <div class="content_wrapper pt-0">
          
            <div class="row highlights">
                <div class="col-lg-3 col-md-6 col-6 pb-3 pb-lg-0" data-aos="fade-down" data-aos-delay="" data-aos-duration="1200">
                    <div class="item vm_content_box_1 h-100">
                        <p class="text-uppercase">Contacts</p>
                        <h2 class="vm_font_semi_bold lh-base d-flex">
                            <i class="bi bi-person pe-1"></i>
                            <div class="value" akhi="350">0</div>
                        </h2>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-6 pb-3 pb-lg-0" data-aos="fade-down" data-aos-delay="" data-aos-duration="1200">
                    <div class="item vm_content_box_1 h-100">
                        <div class="d-md-flex my-2 my-md-0 justify-content-between">
                            <p class="text-uppercase">Customers</p>
                            <div class="d-flex align-items-center vm_bg_light_green px-2">
                                <h5><i class="bi bi-arrow-up text-success"></i></h5>
                                <p class="text-uppercase vm_font_bold text-success">+02.00 %</p>
                            </div>
                        </div>
                        <h2 class="vm_font_semi_bold lh-base d-flex">
                            <i class="bi bi-people pe-1"></i>
                    <div class="value" akhi={customer.length}>{customer.length}</div>

                        </h2>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-6 pb-3 pb-lg-0" data-aos="fade-down" data-aos-delay="" data-aos-duration="1200">
                    <div class="item vm_content_box_1 h-100">
                        <div class="d-md-flex my-2 my-md-0 justify-content-between">
                            <p class="text-uppercase">Inventory</p>
                            <div class="d-flex align-items-center vm_bg_light_red px-2">
                                <h5><i class="bi bi-arrow-down text-danger"></i></h5>
                                <p class="text-uppercase vm_font_bold text-danger">+15.25 %</p>
                            </div>
                        </div>
                        <h2 class="vm_font_semi_bold lh-base d-flex">
                            <i class="bi bi-car-front pe-1"></i>
                            <div class="value" akhi={localInventory.length}>{localInventory.length}</div>
                        </h2>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-6 pb-3 pb-lg-0" data-aos="fade-down" data-aos-delay="" data-aos-duration="1200">
                    <div class="item vm_content_box_1 h-100">
                        <div class="d-md-flex my-2 my-md-0 justify-content-between">
                            <p class="text-uppercase">Revenue</p>
                            <div class="d-flex align-items-center vm_bg_light_green px-2">
                                <h5><i class="bi bi-arrow-up vm_text_color_secondary"></i></h5>
                                <p class="text-uppercase vm_font_bold vm_text_color_secondary">+29.08 %</p>
                            </div>
                        </div>
                        <h2 class="vm_font_semi_bold lh-base d-flex">
                            <span class="currency">CAD</span>
                            <div class="value currency_value" akhi="91250">0</div>
                        </h2>
                    </div>
                </div>
            </div>

            <div class="row pt-4">
                <div class="col-md-6 pb-4 pb-lg-0" data-aos="fade-down" data-aos-delay="" data-aos-duration="1200">
                    <div class="vm_content_box_1 h-100">
                        <div class="title d-flex justify-content-between">
                            <h2>Latest Inqueries</h2>
                            <div class="d-flex">
                                <div class="btn_shake">
                                    <i class="bi bi-bell-fill text-danger fs-6 btn_shake"></i>
                                </div>
                                <span>3</span>
                            </div>
                        </div>
                        <div class="content" style={{height: "280px"}}>
                            <div>
                            {inquiry.length==0?
                <p style={{ textAlign: "center" }}>No Latest Inquiry available</p>
                :

(inquiry.map((item, index) => (  
                                <div class="border-bottom pb-3 pt-2">
                              <Link to={`/leads-details/${item.customer_id}`}> 
                                        <p><span class="vm_font_bold"><i class="bi bi-calendar-week me-1"></i> Inquery Date</span> : {formatDate(item.created_at)}</p>
                                        <h4 class="vm_font_semi_bold lh-base pb-1">{item.vehicle_name}</h4>
                                        <div class="d-md-flex justify-content-between">
                                            <p class="lh-1"><span class="vm_font_semi_bold">Price Range</span> :
                                                <span class="vm_font_semi_bold vm_text_color_secondary">
                                                    <span class="currency"> CAD</span>{item.min_price && item.min_price.toLocaleString()} -
                                                    <span class="currency"> CAD</span>{item.max_price && item.max_price.toLocaleString()}
                                                </span>
                                            </p>
                                            <p class="lh-1 pt-2 pt-md-0"><span class="vm_font_semi_bold"><i class="bi bi-speedometer"></i> Milage</span> : {item.min_km && item.min_km.toLocaleString()} km - {item.max_km && item.max_km.toLocaleString()} Km</p>
                                        </div>
                                  </Link>
                                </div>
                               ))
                               )}
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 pb-4 pb-lg-0" data-aos="fade-down" data-aos-delay="" data-aos-duration="1200">
                    <div class="vm_content_box_1">
                        <div class="title">
                            <h2>Monthly Sales Report</h2>
                            <div class="dropdown">
                                <i class="bi bi-three-dots-vertical vm_cursor_pointer" data-bs-toggle="dropdown" aria-expanded="false"></i>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" href="#">Action</a></li>
                                    <li><a class="dropdown-item" href="#">Another action</a></li>
                                    <li><a class="dropdown-item" href="#">Something else here</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="content">
                        {/* <canvas id="dashboardPieChart1" width="680" height="680" style={{display: "block", boxSizing: "borderBox", height: "272px",width: "272px"}}></canvas> */}
                        <canvas id="dashboardBarChart1" width="1520" height="760" style={{display: "block", boxSizing: "borderBox", height: "304px", width: "608px"}}></canvas>
                        </div>
                    </div>
                </div>
            </div>


            <div class="row pt-4">
                <div class="col-lg-6 pb-4 pb-lg-0" data-aos="fade-down" data-aos-delay="" data-aos-duration="1200">
                    <div class="vm_content_box_1">
                        <div class="title">
                            <h2>Monthly Sales Report</h2>
                            <div class="dropdown">
                                <i class="bi bi-three-dots-vertical vm_cursor_pointer" data-bs-toggle="dropdown" aria-expanded="false"></i>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" href="#">Action</a></li>
                                    <li><a class="dropdown-item" href="#">Another action</a></li>
                                    <li><a class="dropdown-item" href="#">Something else here</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="content">
                        <canvas id="dashboardBarChart2" width="1520" height="760" style={{display: "block", boxSizing: "borderBox", height: "304px", width: "608px"}}></canvas>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 pb-4 pb-md-0" data-aos="fade-down" data-aos-delay="" data-aos-duration="1200">
                    <div class="vm_content_box_1 h-100">
                        <div class="title">
                            <h2>Inventory Report</h2>
                            <div class="dropdown">
                                <i class="bi bi-three-dots-vertical vm_cursor_pointer" data-bs-toggle="dropdown" aria-expanded="false"></i>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" href="#">Action</a></li>
                                    <li><a class="dropdown-item" href="#">Another action</a></li>
                                    <li><a class="dropdown-item" href="#">Something else here</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="content">
                        <canvas id="dashboardPieChart1" width="680" height="680" style={{display: "block", boxSizing: "borderBox", height: "272px", width: "272px"}}></canvas>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6" data-aos="fade-down" data-aos-delay="" data-aos-duration="1200">
                    <div class="vm_content_box_1 h-100">
                        <div class="title">
                            <h2>Inventory Report</h2>
                            <div class="dropdown">
                                <i class="bi bi-three-dots-vertical vm_cursor_pointer" data-bs-toggle="dropdown" aria-expanded="false"></i>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" href="#">Action</a></li>
                                    <li><a class="dropdown-item" href="#">Another action</a></li>
                                    <li><a class="dropdown-item" href="#">Something else here</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="content">
                          <canvas id="dashboardPieChart2" width="680" height="680" style={{display: "block", boxSizing: "borderBox", height: "272px", width: "272px"}}></canvas>
                        </div>
                    </div>
                </div>
            </div>

          
            <div class="row pt-4">
                <div class="col-lg-6 pb-4 pb-lg-0" data-aos="fade-down" data-aos-delay="" data-aos-duration="1200">
                    <div class="vm_content_box_1 h-100" style={{height:"400px"}}>
                        <div class="title">
                            <h2>Inventory - Approval Pending</h2>
                            <div class="dropdown">
                                <i class="bi bi-three-dots-vertical vm_cursor_pointer" data-bs-toggle="dropdown" aria-expanded="false"></i>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" href="#">Action</a></li>
                                    <li><a class="dropdown-item" href="#">Another action</a></li>
                                    <li><a class="dropdown-item" href="#">Something else here</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="content">
                            <p>See and talk to your users and leads immediately by importing your data into the Front Dashboard platform.</p>
                        </div>
                        <div class="subtitle_1">
                            <h2>Latest Arrival</h2>
                        </div>
                        <div class="content pt-3">
                            <div class="d-flex align-items-center justify-content-between pb-3" data-aos="fade-right" data-aos-delay="" data-aos-duration="1200">
                                <div class="d-flex align-items-center">
                                    <div class="circle_img bg-light" style={{background: "url('https://www.topgear.com/sites/default/files/cars-car/image/2021/02/cx-5-skyactiv-g-awd-gt-sport-auto-action-3.jpg')"}}></div>
                                    <div class="ps-2">
                                        <p class="vm_font_semi_bold">Mazda 2023</p>
                                        <p>03 - April - 2023</p>
                                    </div>
                                </div>
                                <div>
                                    <button class="btn_light"><i class="bi bi-pencil-square"></i> Edit</button>
                                </div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between pb-3" data-aos="fade-right" data-aos-delay="" data-aos-duration="1200">
                                <div class="d-flex align-items-center">
                                    <div class="circle_img bg-light" style={{background: "url('https://media.cnn.com/api/v1/images/stellar/prod/220822155902-01-new-delorean-2022.jpg?c=16x9&q=h_720,w_1280,c_fill/f_webp')"}}></div>
                                    <div class="ps-2">
                                        <p class="vm_font_semi_bold">Mazda 2023</p>
                                        <p>03 - April - 2023</p>
                                    </div>
                                </div>
                                <div>
                                    <button class="btn_light"><i class="bi bi-pencil-square"></i> Edit</button>
                                </div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between pb-3" data-aos="fade-right" data-aos-delay="" data-aos-duration="1200">
                                <div class="d-flex align-items-center">
                                    <div class="circle_img bg-light" style={{background: "url('https://www.topgear.com/sites/default/files/cars-car/image/2021/02/cx-5-skyactiv-g-awd-gt-sport-auto-action-3.jpg')"}}></div>
                                    <div class="ps-2">
                                        <p class="vm_font_semi_bold">Mazda 2023</p>
                                        <p>03 - April - 2023</p>
                                    </div>
                                </div>
                                <div>
                                    <button class="btn_light"><i class="bi bi-pencil-square"></i> Edit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6" style={{height:"400px"}} data-aos="fade-down" data-aos-delay="" data-aos-duration="1200">
                    <div class="vm_content_box_1 h-100">
                        <div class="title">
                            <h2>My To Do List</h2>
                            <div class="dropdown">
                                <i class="bi bi-three-dots-vertical vm_cursor_pointer" data-bs-toggle="dropdown" aria-expanded="false"></i>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" href="#">Action</a></li>
                                    <li><a class="dropdown-item" href="#">Another action</a></li>
                                    <li><a class="dropdown-item" href="#">Something else here</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="content">
                            <div class="d-flex align-items-start pb-3" data-aos="fade-right" data-aos-delay="" data-aos-duration="1200">
                                <input class="mt-1 me-2" type="checkbox" name="select" id="todo1"/>
                                <label for="todo1">
                                    <p class="vm_font_semi_bold text-success">Make Initial Call</p>
                                    <p>Contact : Crystal Miles</p>
                                </label>
                            </div>
                            <div class="d-flex align-items-start pb-3" data-aos="fade-right" data-aos-delay="" data-aos-duration="1200">
                                <input class="mt-1 me-2" type="checkbox" name="select" id="todo2"/>
                                <label for="todo2">
                                    <p class="vm_font_semi_bold text-decoration-line-through">Make Initial Call</p>
                                    <p>Contact : Crystal Miles</p>
                                </label>
                            </div>
                            <div class="d-flex align-items-start pb-3" data-aos="fade-right" data-aos-delay="" data-aos-duration="1200">
                                <input class="mt-1 me-2" type="checkbox" name="select" id="todo3"/>
                                <label for="todo3">
                                    <p class="vm_font_semi_bold text-success">Make Initial Call</p>
                                    <p>Contact : Crystal Miles</p>
                                </label>
                            </div>
                            <div class="d-flex align-items-start pb-3" data-aos="fade-right" data-aos-delay="" data-aos-duration="1200">
                                <input class="mt-1 me-2" type="checkbox" name="select" id="todo4"/>
                                <label for="todo4">
                                    <p class="vm_font_semi_bold text-decoration-line-through">Call New Lead Submission</p>
                                    <p>Contact : Jaskaran Singh</p>
                                </label>
                            </div>
                            <div class="d-flex align-items-start pb-3" data-aos="fade-right" data-aos-delay="" data-aos-duration="1200">
                                <input class="mt-1 me-2" type="checkbox" name="select" id="todo5"/>
                                <label for="todo5">
                                    <p class="vm_font_semi_bold text-success">Make Initial Call</p>
                                    <p>Contact : Crystal Miles</p>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

   
            <div class="pt-4">
                <div class="vm_content_box_1">
                    <div class="title">
                        <h2>Customers</h2>
                        <div class="dropdown">
                            <i class="bi bi-three-dots-vertical vm_cursor_pointer" data-bs-toggle="dropdown" aria-expanded="false"></i>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" href="#">Action</a></li>
                                <li><a class="dropdown-item" href="#">Another action</a></li>
                                <li><a class="dropdown-item" href="#">Something else here</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="content overflow_y_hidden">
                    <div class="row pt-3">
                          
                          <div class="col-md-9 form-group" >
                                  <div class="d-flex input_wrapper">
                                  <div><label>Show   <select name="example_length" aria-controls="example" value={perPage} onChange={(e) => {setCurrentPage(0);setPerPage(Number(e.target.value))}}>
                                      <option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option>
                                    </select>  entries</label>
                                    </div>
                              
                   
                                  </div>
                              </div>
  
                              <div class="col-md-3 form-group">
                                  <div class="d-flex input_wrapper">
                                      <div class="icon_wrapper">
                                          <i class="bi bi-search icon"></i>
                                      </div>
                                      <input class="form-control" name="searchQuery" id="searchQuery" placeholder="Search..." type="text" 
                                      value={searchQuery} onChange={(e) => {setSearchQuery(e.target.value)}}/>
                                  </div>
                              </div>
                          </div>


                        <table id="example" class="display dataTable dtr-inline" >
                            <thead>
                                <tr>
                                    <th>Customer Name</th>
                                    <th>Vehicle Name</th>
                                    <th>Status</th>
                                    <th>Progress</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            {filteredResult.length === 0 ? 
                

                <div class="row" style={{margin: "10px -900px 10px 10px"}}>
                <p id="recordMessage" valign="top" class="odd" style={{ textAlign: "center" }} >No records found</p> 
                </div>
                  : null} 
                            {filteredResult.slice((currentPage * perPage), ((currentPage * perPage) + perPage)).map((item, index) => (

                                <tr data-aos="fade-right" data-aos-delay="" data-aos-duration="1200">
                                    <td>
                                        <div class="d-flex align-items-start">
                                            <input class="mt-1 me-2" type="checkbox" name="select" id="ramesh_kumar"/>
                                            <label for="ramesh_kumar">
                                                <p class="vm_font_semi_bold">{item.job_title}</p>
                                                <p>{item.first_name} {item.last_name}</p>
                                            </label>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-md-flex align-items-center">
                                            <div class="circle_img bg-light" style={{background: "url('https://www.topgear.com/sites/default/files/cars-car/image/2021/02/cx-5-skyactiv-g-awd-gt-sport-auto-action-3.jpg')"}}></div>
                                            <div class="ps-md-2">
                                                <p class="vm_font_semi_bold">Mazda 2023</p>
                                                <p>03 - April - 2023</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex align-items-center position-absolute mt-2">
                                            <span class="bg-primary rounded-1 px-2 py-1 text-white">Approved</span>
                                        </div>
                                    </td>
                                    <td>
                                        <p class="text-center vm_font_semi_bold">80%</p>
                                        <div class="progress" style={{height:"5px"}}>
                                            <div class="progress-bar bg-primary" role="progressbar" style={{width: "80%"}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </td>
                                    <td><button class="btn_light position-absolute mt-2"><i class="bi bi-eye-fill"></i> View</button></td>
                                </tr>
                                ))}
                               
                               
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>Customer Name</th>
                                    <th>Vehicle Name</th>
                                    <th>Status</th>
                                    <th>Progress</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                        </table>

                        <div class="col-md-3" style={{ float: "left", padding: "20px 0px 0px 0px" }}>
                                        {filteredResult.length > 0 && `Showing 1 to ${Math.min(perPage, filteredResult.length)} of ${filteredResult.length} entries`}
                                    </div><div class="col-md-9" style={{ float: "right" }}>

                                        <ReactPaginate style={{ float: "right" }}
                                            previousLabel={"< Prev"}
                                            nextLabel={"Next >"}
                                            breakLabel={'...'}
                                            pageCount={Math.ceil(filteredResult.length / perPage)}
                                            onPageChange={handlePageChange}
                                            containerClassName={"pagination pagination-sm justify-content-center custom-pagination"} // Bootstrap pagination class
                                            disabledClassName={"disabled"}
                                            breakClassName={['page-item']}
                                            breakLinkClassName={'page-link'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link'}
                                            previousClassName={'page-item'}
                                            previousLinkClassName={'page-link'}
                                            nextClassName={'page-item'}
                                            nextLinkClassName={'page-link'}
                                            activeClassName={['active']} />

                                    </div>


                    </div>
                </div>
            </div>

         
            <div class="row pt-4">
                <div class="col-lg-6 pb-4 pb-lg-0" data-aos="fade-down" data-aos-delay="" data-aos-duration="1200">
                    <div class="vm_content_box_1">
                        <div class="title">
                            <h2>Task</h2>
                            <div class="dropdown">
                                <i class="bi bi-three-dots-vertical vm_cursor_pointer" data-bs-toggle="dropdown" aria-expanded="false"></i>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" href="#">Action</a></li>
                                    <li><a class="dropdown-item" href="#">Another action</a></li>
                                    <li><a class="dropdown-item" href="#">Something else here</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="content pt-3" style={{height: "300px"}}>
                            <div class="d-flex align-items-start pb-3" data-aos="fade-right" data-aos-delay="" data-aos-duration="1200">
                                <input class="mt-1 me-2" type="checkbox" name="select" id="task1"/>
                                <label for="task1">
                                    <p class="vm_font_semi_bold">Make Initial Call</p>
                                    <p>Contact : Crystal Miles</p>
                                </label>
                            </div>
                            <div class="d-flex align-items-start pb-3" data-aos="fade-right" data-aos-delay="" data-aos-duration="1200">
                                <input class="mt-1 me-2" type="checkbox" name="select" id="task2"/>
                                <label for="task2">
                                    <p class="vm_font_semi_bold">Make Initial Call</p>
                                    <p>Contact : Crystal Miles</p>
                                </label>
                            </div>
                            <div class="d-flex align-items-start pb-3" data-aos="fade-right" data-aos-delay="" data-aos-duration="1200">
                                <input class="mt-1 me-2" type="checkbox" name="select" id="task3"/>
                                <label for="task3">
                                    <p class="vm_font_semi_bold">Make Initial Call</p>
                                    <p>Contact : Crystal Miles</p>
                                </label>
                            </div>
                            <div class="d-flex align-items-start pb-3" data-aos="fade-right" data-aos-delay="" data-aos-duration="1200">
                                <input class="mt-1 me-2" type="checkbox" name="select" id="task4"/>
                                <label for="task4">
                                    <p class="vm_font_semi_bold">Call New Lead Submission</p>
                                    <p>Contact : Jaskaran Singh</p>
                                </label>
                            </div>
                            <div class="d-flex align-items-start pb-3" data-aos="fade-right" data-aos-delay="" data-aos-duration="1200">
                                <input class="mt-1 me-2" type="checkbox" name="select" id="task5"/>
                                <label for="task5">
                                    <p class="vm_font_semi_bold">Make Initial Call</p>
                                    <p>Contact : Crystal Miles</p>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6" data-aos="fade-down" data-aos-delay="" data-aos-duration="1200">
                    <div class="vm_content_box_1 h-100">
                        <div class="title">
                            <h2>Appointment</h2>
                            <div class="dropdown">
                                <i class="bi bi-three-dots-vertical vm_cursor_pointer" data-bs-toggle="dropdown" aria-expanded="false"></i>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" href="#">Action</a></li>
                                    <li><a class="dropdown-item" href="#">Another action</a></li>
                                    <li><a class="dropdown-item" href="#">Something else here</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="content">
                            <div class="d-flex align-items-start pb-3" data-aos="fade-right" data-aos-delay="" data-aos-duration="1200">
                                <input class="mt-1 me-2" type="checkbox" name="select" id="app1"/>
                                <label for="app1">
                                    <p class="vm_font_semi_bold">Familyt Da (Regional Holiday)</p>
                                    <p>12:00am - 11:56pm</p>
                                </label>
                            </div>
                            <div class="d-flex align-items-start pb-3" data-aos="fade-right" data-aos-delay="" data-aos-duration="1200">
                                <input class="mt-1 me-2" type="checkbox" name="select" id="app2"/>
                                <label for="app2">
                                    <p class="vm_font_semi_bold">Nova Scotia Heritage Day</p>
                                    <p>12:00am - 11:56pm</p>
                                </label>
                            </div>
                            <div class="d-flex align-items-start pb-3" data-aos="fade-right" data-aos-delay="" data-aos-duration="1200">
                                <input class="mt-1 me-2" type="checkbox" name="select" id="app3"/>
                                <label for="app3">
                                    <p class="vm_font_semi_bold">Louis Riel Day</p>
                                    <p>12:00am - 11:56pm</p>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


        </div>
    );
}
export default ViewDashboard;