import ViewInventory  from "./ViewInventory";
import { React, useState,useContext, useEffect } from "react";
import {Link } from "react-router-dom";
import logo from '../img/logo_main_black.webp';
import logoSmall from '../img/atto_desk_logo_icon_white.webp'
import $ from 'jquery';
import UserContext from './UserContext';

function SideNav(){

    const { username, userDetails } = useContext(UserContext);

    // useEffect(() => {
    //     console.log("UserProfile - username:", username); // Debugging log
    //     console.log("UserProfile - userDetails:", userDetails.user_role); // Debugging log
    //   }, [username, userDetails]);
    
    // function myFunction(x) {
    //     if (x.matches) {
    //       document.getElementById("left_panel").classList.add("menuHoverClass");
    //     } else {
    //       document.getElementById("left_panel").classList.remove("menuHoverClass");
    //     }
    //   }
    //   var x = window.matchMedia("(max-width: 1024px)");
    //   myFunction(x);
    //   x.addListener(myFunction);
      
      // -------------------------------
      // Active Menu Background
      // -------------------------------
      var li = $("#leftmenu li").click(function () {
        li.removeClass("vm_bg_secondary");
        $(this).addClass("vm_bg_secondary");
      });
      var ul_li = $("#leftmenu li ul li").click(function () {
        ul_li.removeClass("vm_bg_gray1");
        $(this).addClass("vm_bg_gray1");
      });

    return(
<div class="d-flex">
    <div class="left_panel position-fixed " id="left_panel">

    <Link to={'/login'}> 
        <div class="px-3 logo bg-dark align-items-center" id="mainlogo_big" style={{display: "flex"}}>
            <img width="140" src={logo} alt="Attodesk Logo Big"/>
        </div>
        <div class="pe-2 logo bg-dark justify-content-end align-items-center" id="mainlogo_small" style={{display: "none"}}>
            <img width="40" src={logoSmall} alt="Attodesk Logo Small"/>
        </div>
        </Link>
  


<div class="navigation" id="navigation">


<ul class="cd-accordion cd-accordion--animated margin-top-lg margin-bottom-lg ps-2" id="leftmenu">
{/* ------------Menu dashboard---------- */}
            {/* <li class="cd-accordion__item cd-accordion__item--has-children">
            <Link to={'/view-dashboard'}>
                    <div class="menu_item_desktop_expand">
                        <label class="d-flex justify-content-between w-100 cd-accordion__label cd-accordion__label--icon-folder px-2">
                            <div>
                                <i class="bi bi-speedometer2"></i>
                                <span>Dashboard</span>
                            </div>
                         
                        </label>
                    </div>
       
                <label class="cd-accordion__label cd-accordion__label--icon-folder px-2 menu_item_desktop_collapsed" for="group-1">
                    <i class="bi bi-person-rolodex me-1"></i>
                </label>
                </Link>
            </li> */}

<li class="cd-accordion__item cd-accordion__item--has-children">
            <Link to={'/statistics'}>
                    <div class="menu_item_desktop_expand">
                        <label class="d-flex justify-content-between w-100 cd-accordion__label cd-accordion__label--icon-folder px-2">
                            <div>
                                <i class="bi bi-speedometer2"></i>
                                <span>Statistics</span>
                            </div>
                         
                        </label>
                    </div>
       
                <label class="cd-accordion__label cd-accordion__label--icon-folder px-2 menu_item_desktop_collapsed" for="group-1">
                    <i class="bi bi-person-rolodex me-1"></i>
                </label>
                </Link>
            </li> 
          
            <li class="cd-accordion__item cd-accordion__item--has-children">
            <Link to={'/view-customers'}>
                    <div class="menu_item_desktop_expand">
                        <label class="d-flex cd-accordion__label cd-accordion__label--icon-folder px-2">
                            <i class="bi bi-person"></i>
                            <span>Customer</span>
                        </label>
                    </div>
               
                <label class="cd-accordion__label cd-accordion__label--icon-folder px-2 menu_item_desktop_collapsed" for="group-1">
                    <i class="bi bi-person me-1"></i>
                </label>
                </Link>
            </li>

            {/* <li class="cd-accordion__item cd-accordion__item--has-children">
            <Link to={'/view-inventory'}>
                    <div class="menu_item_desktop_expand">
                        <label class="d-flex cd-accordion__label cd-accordion__label--icon-folder px-2">
                            <i class="bi bi-car-front-fill"></i>
                            <span>Inventory</span>
                        </label>
                    </div>
                
                <label class="cd-accordion__label cd-accordion__label--icon-folder px-2 menu_item_desktop_collapsed" for="group-1">
                    <i class="bi bi-car-front-fill me-1"></i>
                </label>
                </Link>
            </li> */}

            <li class="cd-accordion__item cd-accordion__item--has-children">
            <Link to={'/leads-details'}>
                    <div class="menu_item_desktop_expand">
                        <label class="d-flex cd-accordion__label cd-accordion__label--icon-folder px-2">
                            <i class="bi bi-person-rolodex"></i>
                            <span>Leads</span>
                        </label>
                    </div>
              
                <label class="cd-accordion__label cd-accordion__label--icon-folder px-2 menu_item_desktop_collapsed" for="group-1">
                    <i class="bi bi-person-rolodex me-1"></i>
                </label>
                </Link>
            </li>


            {/* <!-- -------------------- -->
            <!-- Menu Setting -->
            <!-- -------------------- --> */}
                 {/* {userDetails.user_role === 'Sales' ? null : */}
            <li class="cd-accordion__item cd-accordion__item--has-children">
                <input class="cd-accordion__input" type="checkbox" name="group-1" id="group-1" />
                <a href="index.php">
                    <label class="menu_item_desktop_expand cd-accordion__label cd-accordion__label--icon-folder px-2" for="group-1">
                        <div class="d-flex">
                            <i class="bi bi-gear"></i>
                            <span>Settings</span>
                        </div>
                        <i class="bi bi-caret-down-fill"></i>
                    </label>
                </a>
                <label class="cd-accordion__label cd-accordion__label--icon-folder px-2 menu_item_desktop_collapsed" for="group-1">
                    <i class="bi bi-speedometer2 me-1"></i>
                </label>

                {/* <!-- Setting Sub Menu Level 1 --> */}
                <ul class="cd-accordion__sub cd-accordion__sub--l1">

                    <li class="cd-accordion__item cd-accordion__item--has-children">
                    <Link to={'/setting-email-template'}>
                            <label class="cd-accordion__label cd-accordion__label--icon-folder menu_text" for="sub-group-1">
                                <div class="d-flex justify-content-between">
                                    <span>
                                        <i class="bi bi-envelope pe-1"></i> Email
                                    </span>
                                   
                                </div>
                            </label>
                   </Link>
                    </li>

                    <li class="cd-accordion__item cd-accordion__item--has-children">
                    <Link to={'/setting-sms-template'}>
                            <label class="cd-accordion__label cd-accordion__label--icon-folder menu_text" for="sub-group-1">
                                <div class="d-flex justify-content-between">
                                    <span>
                                        <i class="bi bi-chat-right-dots pe-1"></i> SMS
                                    </span>
                                   
                                </div>
                            </label>
                   </Link>
                    </li>

                  <li class="cd-accordion__item cd-accordion__item--has-children">
                  <Link to={'/setting-pin-template'}>
                            <label class="cd-accordion__label cd-accordion__label--icon-folder menu_text" for="sub-group-1">
                                <div class="d-flex justify-content-between">
                                    <span>
                                        <i class="bi bi-pin-map pe-1"></i> Pin
                                    </span>
                                </div>
                            </label>
                            </Link>
                    </li>
                    <li class="cd-accordion__item cd-accordion__item--has-children">
                    <Link to={'/setting-meeting-template'}>
                            <label class="cd-accordion__label cd-accordion__label--icon-folder menu_text" for="sub-group-1">
                                <div class="d-flex justify-content-between">
                                    <span>
                                        <i class="bi bi-calendar-check pe-1"></i> Meeting
                                    </span>
                                </div>
                            </label>
                            </Link>
                    </li> 

<li class="cd-accordion__item cd-accordion__item--has-children">
           <Link to={'/setting-note-template'}>
                            <label class="cd-accordion__label cd-accordion__label--icon-folder menu_text" for="sub-group-1">
                                <div class="d-flex justify-content-between">
                                    <span>
                                        <i class="bi bi-sticky pe-1"></i> Note
                                    </span>
                                </div>
                            </label>
                        </Link>
                    </li>

                       <li class="cd-accordion__item cd-accordion__item--has-children">
                       <Link to={'/setting-upload-template'}>
                            <label cla ss="cd-accordion__label cd-accordion__label--icon-folder menu_text" for="sub-group-1">
                                <div class="d-flex justify-content-between">
                                    <span>
                                        <i class="bi bi-upload pe-1"></i> Upload
                                    </span>
                                </div>
                            </label>
                            </Link>
                    </li>


                    {/* <li class="cd-accordion__item cd-accordion__item--has-children">
                            <Link to={'/setting-invoice-preset'}>
                            <label class="cd-accordion__label cd-accordion__label--icon-folder menu_text" for="sub-group-1">
                                <div class="d-flex justify-content-between">
                                    <span>
                                        <i class="bi bi-receipt-cutoff pe-1"></i> Invoice Preset
                                    </span>
                                </div>
                            </label>
                        </Link>
                    </li>
                    <li class="cd-accordion__item cd-accordion__item--has-children">
                            <Link to={'/setting-image-preset'}>
                            <label class="cd-accordion__label cd-accordion__label--icon-folder menu_text" for="sub-group-1">
                                <div class="d-flex justify-content-between">
                                    <span>
                                    <i class="bi bi-images pe-1"></i> Image Preset
                                    </span>
                                </div>
                            </label>
                        </Link>
                    </li> */}

                  
                 
                </ul>
            </li>
            {/* } */}
  
            {userDetails.user_role === 'Coordinator' ? null :
             <li class="cd-accordion__item cd-accordion__item--has-children">
            <Link to={'/view-team'}>
                    <div class="menu_item_desktop_expand">
                        <label class="d-flex justify-content-between w-100 cd-accordion__label cd-accordion__label--icon-folder px-2">
                            <div>
                                <i class="bi bi-people"></i>
                                <span>Team</span>
                            </div>
                        </label>
                    </div>
       
                <label class="cd-accordion__label cd-accordion__label--icon-folder px-2 menu_item_desktop_collapsed" for="group-1">
                    <i class="bi bi-person-rolodex me-1"></i>
                </label>
                </Link>
            </li> 
 } 


                   <li class="cd-accordion__item cd-accordion__item--has-children">
                            <Link to={'/email-logs'}>
                            <label class="cd-accordion__label cd-accordion__label--icon-folder menu_text" for="sub-group-1">
                                <div class="d-flex justify-content-between">
                                    <span>
                                    <i class="bi bi-envelope pe-1"></i> Email
                                    </span>
                                </div>
                            </label>
                        </Link>
                    </li>

</ul>
  </div>

 </div>
 </div>
    );
}
export default SideNav;