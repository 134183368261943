import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Chart } from "chart.js/auto";
import SideNav from "./SideNav"; // Assuming you have this component
import TopBar from "./TopBar";   // Assuming you have this component
import "./Statistics.css"; // Custom styles for the page
import DatePicker from "react-datepicker";  // Import DatePicker
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import moment from "moment";



const Statistics = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const chartRef = useRef(null);            
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const chartInstanceRef = useRef(null);
  const [startDate, setStartDate] = useState(moment().startOf("month").toDate()); // Default to start of this month
  const [endDate, setEndDate] = useState(moment().endOf("month").toDate()); // Default to end of this month
  const [selectedOption, setSelectedOption] = useState({ value: "thisMonth", label: "This Month" });
  const [showDatePicker, setShowDatePicker] = useState(true);
  const [filterLoading, setFilterLoading] = useState(false); // Loading state
  const [emails, setEmails] = useState([]);

  const presetOptions = [
    { value: "today", label: "Today" },
    { value: "yesterday", label: "Yesterday" },
    { value: "last7", label: "Last 7 Days" },
    { value: "last30", label: "Last 30 Days" },
    { value: "thisMonth", label: "This Month" },
    { value: "lastMonth", label: "Last Month" },
    { value: "custom", label: "Custom Range" }
  ];


  
  // Fetch statistics data
  useEffect(() => {
    // Ensure the dates are not in the future
    const currentDate = moment().endOf("day"); // Current date (end of the day to include the entire day)

    // Validate and sanitize startDate
    const validatedStartDate = moment(startDate).isValid() && moment(startDate).isBefore(currentDate)
        ? moment(startDate).startOf("day")
        : currentDate.clone().subtract(7, 'days'); // Default to 7 days before current date

    // Validate and sanitize endDate
    const validatedEndDate = moment(endDate).isValid() && moment(endDate).isBefore(currentDate)
        ? moment(endDate).endOf("day")
        : currentDate;

    const queryParams = new URLSearchParams({
        startDate: validatedStartDate.format('MMM D'), // Format to 'YYYY-MM-DD'
        endDate: validatedEndDate.format('MMM D') // Format to 'YYYY-MM-DD'
    }).toString();

  
    axios
      .get(`${API_URL}/smtp-statistics?${queryParams}`)
      .then((response) => {
        setStats(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });

      axios.get(`${API_URL}/smtp-emails?${queryParams}`)
  .then(response => {
    const emails = response.data.events || [];  // Ensure it's an empty array if no data
    setEmails(emails);
    if (emails.length === 0) {
      console.log('No records found'); // This can be useful for debugging
    }
  })
  .catch(err => {
    console.error('Error fetching sent emails:', err);
    setError(err.message);
  });
  }, [API_URL, startDate, endDate]);

  

  useEffect(() => {
    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }
  
    if (stats && stats.range) {
      const dateDataMap = {};
  
      // Parse the date range using moment.js
      const dateRange = stats.range.split("|");
      const startDate = moment(dateRange[0]);
      const endDate = moment(dateRange[1]);
  
      let currentDate = moment(startDate);
  
      // Loop over each day in the range
      while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, "day")) {
        const dateString = currentDate.format("YYYY-MM-DD"); // Format as YYYY-MM-DD
        dateDataMap[dateString] = {
          requests: 0,
          delivered: 0,
          bounces: 0,
          opens: 0,
          clicks: 0,
        };
  
        // Assuming stats contains individual totals for requests, delivered, etc.
        // You could fill in the values for each date here if available
        // (e.g., use stats.requests for the whole period, or adjust based on some other available logic)
  
        currentDate.add(1, "days");
      }
  
      // Prepare the labels and data for the chart
      const dateLabels = [];
      const requestsData = [];
      const deliveredData = [];
      const bouncesData = [];
      const opensData = [];
      const clicksData = [];
  
      // Fill the datasets with default values or any available stats data
      currentDate = moment(startDate);
      while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, "day")) {
        const dateString = currentDate.format("YYYY-MM-DD");
        dateLabels.push(currentDate.format("MMM D")); // Use "MMM D" for display (e.g., "Dec 3")
  
        const dataForDate = dateDataMap[dateString] || {
          requests: 0,
          delivered: 0,
          bounces: 0,
          opens: 0,
          clicks: 0,
        };
  
        // Push data for each chart category
        requestsData.push(dataForDate.requests);
        deliveredData.push(dataForDate.delivered);
        bouncesData.push(dataForDate.bounces);
        opensData.push(dataForDate.opens);
        clicksData.push(dataForDate.clicks);
  
        currentDate.add(1, "days");
      }
  
      // Create the chart with the data
      const chart = new Chart(chartRef.current, {
        type: "line",
        data: {
          labels: dateLabels, // Use the formatted date labels (e.g., ["Dec 1", "Dec 2", "Dec 3"])
          datasets: [
            {
              label: "Emails Sent",
              data: [stats?.requests],
              borderColor: "#2196F3", // Blue color for Sent
              fill: false,
              tension: 0.1,
            },
            {
              label: "Emails Delivered",
              data: [stats?.delivered],
              borderColor: "#4CAF50", // Green color for Delivered
              fill: false,
              tension: 0.1,
            },
            {
              label: "Bounces",
              data: [stats?.hardBounces + stats?.softBounces],
              borderColor: "#FF5722", // Red color for Bounces
              fill: false,
              tension: 0.1,
            },
            {
              label: "Emails Opened",
              data:  [stats?.opens],
              borderColor: "#FFC107", // Yellow color for Opens
              fill: false,
              tension: 0.1,
            },
            {
              label: "Clicks",
              data:  [stats?.clicks],
              borderColor: "#9C27B0", // Purple color for Clicks
              fill: false,
              tension: 0.1,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: { position: "top" },
            tooltip: { mode: "index", intersect: false },
          },
          scales: {
            x: {
              ticks: { display: true },
            },
            y: {
              beginAtZero: true,
            },
          },
        },
      });
  
      chartInstanceRef.current = chart;
    }
  
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [stats, startDate, endDate]);
  

  
 
  
  

  

  if (loading) {
    return (
      <div className="dashboard">
        <SideNav />
        <TopBar />
        <div className="loader-container">
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status"></div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="dashboard">
        <SideNav />
        <TopBar />
        <div className="error-message">
          <h2>Error</h2>
          <p>{error}</p>
        </div>
      </div>
    );
  }

  if (!stats || stats.length === 0) {
    return (
      <div className="dashboard">
        <SideNav />
        <TopBar />
        <div className="error-message">
          <h2>No statistics data available.</h2>
        </div>
      </div>
    );
  }

  const handleChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (start && end) {
      setShowDatePicker(false);
    }
  };

  const handleSelectChange = (selectedOption) => {
    if (selectedOption) {
      if (selectedOption.value !== "custom") {
      setFilterLoading(true); // Set loading to true when selecting an option
      // Simulate a delay for fetching data (you can replace this with your actual fetch function)
      setTimeout(() => {
        setFilterLoading(false); // Set loading to false when data fetching is complete
      }, 4000); // Simulate fetching delay (2 seconds)
    }
  }
    if (selectedOption.value === "custom") {
      setShowDatePicker(true);
    
    } else {
      setShowDatePicker(false);
    }
    setSelectedOption(selectedOption);
   // setLoading(true);
    // Handle different preset options
    let start = null;
    let end = null;

    switch (selectedOption.value) {
      case "today":
        start = end = moment().toDate();
        break;
      case "yesterday":
        start = end = moment().subtract(1, "days").toDate();
        break;
      case "last7":
        start = moment().subtract(7, "days").toDate();
        end = moment().toDate();
        break;
      case "last30":
        start = moment().subtract(30, "days").toDate();
        end = moment().toDate();
        break;
      case "thisMonth":
        start = moment().startOf("month").toDate();
        end = moment().endOf("month").toDate();
        break;
      case "lastMonth":
        start = moment().subtract(1, "month").startOf("month").toDate();
        end = moment().subtract(1, "month").endOf("month").toDate();
        break;
      case "custom":
        // Allow the user to pick custom dates
        break;
      default:
        break;
    }

    setStartDate(start);
    setEndDate(end);
  };
  return (
    
    <div className="dashboard">
      <SideNav />
      <TopBar />
      <div class="right_panel position contact_section h-100" id="right_panel">
      
      <div className="main_title d-flex align-items-center justify-content-between">
            <h1><i class="bi bi-graph-up-arrow"></i>Email Statistics</h1>
            {filterLoading && (
        <div className="spinner-border spinner-border-sm"  role="status"  style={{ marginRight: "850px" }} >
  </div>
            )}
            <div className="date-range-filter">
         
      <div className="filter-select">
     
        <Select
          value={selectedOption}
          onChange={handleSelectChange}
          options={presetOptions}
          placeholder="Select Date Range"
          styles={{
            control: (base) => ({
              ...base,
              minWidth: '250px', // Makes the dropdown wider
            }),
          }}
        />
      </div>
      <div className="selected-range">
        {startDate && endDate && (
          <p>
            From: {moment(startDate).format("MMM DD, YYYY")} To:{" "}
            {moment(endDate).format("MMM DD, YYYY")}
          </p>
        )}
      </div>
    </div>
  </div>
  <div className="content_wrapper pt-0">
    <div className="row">
      {selectedOption?.value === "custom" && showDatePicker && (
        <div className="custom-date-picker"  style={{ display: "flex", 
          position: "absolute",
          top: "140px",
          right: "20px",justifyContent: "flex-end" }}>
          <DatePicker
            selected={startDate}
            onChange={handleChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            inline
            dateFormat="MMM dd, yyyy"
            placeholderText="Select a date range"
             maxDate={moment().toDate()} 
          />
        </div>
      )}
    </div>
  




      <div className="statistics-container">
        {/* Overview Stats Section */}
        <div className="overview-stats">
          <div className="stat-card">
            <h3>Sent</h3>
            <p>{stats.requests}</p>
          </div>
          <div className="stat-card">
            <h3>Delivered</h3>
            <p>{stats.delivered}</p>
          </div>
          <div className="stat-card">
            <h3>Bounces</h3>
            <p>{stats.hardBounces + stats.softBounces}</p>
          </div>
          <div className="stat-card">
            <h3>Opens</h3>
            <p>{stats.opens}</p>
          </div>
          <div className="stat-card">
            <h3>Clicks</h3>
            <p>{stats.clicks}</p>
          </div>
        </div>

        {/* Line Chart */}
        {/* <div className="chart-container">
             <canvas ref={chartRef} width="400" height="400"></canvas>
        </div> */}

        <div class="vm_content_box_1">
                    <div class="title">
                        <h2>Messages from {moment(startDate).format("MMM DD, YYYY")} to  {moment(endDate).format("MMM DD, YYYY")}</h2>
                     
                       
    </div>
    <div className="content_wrapper pt-0">
      <table id="example" class="display dataTable dtr-inline" >
        <thead>
          <tr>
            <th>Events</th>
            <th>Date</th>
            <th>Subject</th>
            <th>From</th>
            <th>To</th>
            {/* <th>Tags</th> */}
          </tr>
        </thead>
        <tbody>
    
        {emails?.length === 0 ? (
        <tr>
          <td colSpan="5" style={{ textAlign: "center" }}>No records found</td>
        </tr>
      ) : (
        emails?.map((event, index) => (
          <tr key={index}>
            <td>{event.event}</td>
            <td>{moment(event.date).format('MMM D, YYYY h:mm:ss A')}</td>
            <td>{event.subject}</td>
            <td>{event.from}</td>
            <td>{event.email}</td>
            {/* <td>{event.tags || 'None'}</td> */}
          </tr>
           ))
          )}
        </tbody>
      </table>
      </div>
    </div>
    </div>
     
      </div>
    </div>
    </div>

  );
};

export default Statistics;
